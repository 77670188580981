import React, { useState } from "react";


import EditItem1 from "../../components/EditarEmpresa/EditItem1"
import EditItem2 from "../../components/EditarEmpresa/EditItem2"
import EditItem3 from "../../components/EditarEmpresa/EditItem3"
import EditItem4 from "../../components/EditarEmpresa/EditItem4"
import EditItem5 from "../../components/EditarEmpresa/EditItem5"

import { Container, Stages } from './styles';

function EditInfoEmpresa({ location }) {
  const info = location.state;
  
  const [stageForm, setStageForm] = useState(1);
  


  return (
    <Container>
      <Stages>
        <button id={stageForm === 1 ? "select" : null} onClick={() => setStageForm(1)}>
          <strong>1</strong>
        </button>
        <button id={stageForm === 2 ? "select" : null} onClick={() => setStageForm(2)}>
          <strong>2</strong>
        </button>
        <button id={stageForm === 3 ? "select" : null} onClick={() => setStageForm(3)}>
          <strong>3</strong>
        </button>
        <button id={stageForm === 4 ? "select" : null} onClick={() => setStageForm(4)}>
          <strong>4</strong>
        </button>
        {info.acesso_polo && 
          <button id={stageForm === 5 ? "select" : null} onClick={() => setStageForm(5)}>
            <strong>5</strong>
          </button>
        }
      </Stages>
      {stageForm === 1 && <EditItem1 listinfo={info}/> }
      {stageForm === 2 && <EditItem2 listinfo={info}/> }
      {stageForm === 3 && <EditItem3 listinfo={info}/> }
      {stageForm === 4 && <EditItem4 listinfo={info}/> }
      {info.acesso_polo && stageForm === 5 && <EditItem5 listinfo={info}/> }
    </Container>
  );
}

export default EditInfoEmpresa;
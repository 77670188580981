import React, { useState } from 'react';
import { 
  Button,
  Upload,
  message,
} from 'antd';
import crypto from "crypto";

import { UploadOutlined } from '@ant-design/icons';

import { storage } from "../../services/firebase";
import api from "../../services/api";

function UploadDeclaracaoCumprimentoCotaAprendizado() {

  const [hash, setHash] = useState();

  const handleChangeFile = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      return;
    }
  };

  const beforeUploadFile = (file) => {
    // console.log(file);
    const isFile = file.type.indexOf('application/pdf') === 0;
    if (!isFile) {
      message.error('Importar somente arquivos PDF!');
    }
    
    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('Arquivo precisa ter menos de 5MB!');
    }
    return isFile && isLt5M;
  };

  const customUploadFile = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHash(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { declaracao_cumprimento_cota_aprendizado: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
          // setValue(response.data);
        });
      });
    });
  };

  const handleDelFile = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hash}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { declaracao_cumprimento_cota_aprendizado: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };

  return (
    <Upload
      name="contrato"
      beforeUpload={beforeUploadFile}
      onChange={handleChangeFile}
      customRequest={customUploadFile}
      onRemove={handleDelFile}
    >
      <p>
        Declaração de Cumprimento da Cota de Aprendizado (DCCA):
      </p>
      <Button 
        icon={<UploadOutlined />}
        style={{
          margin: "10px",
          width: "25vw",
          color: "rgba(0, 190, 255, 0.9)",
          borderColor: "rgba(0, 190, 255, 0.9)"
        }}
      >
        Upload
      </Button>
    </Upload>
  );
}

export default UploadDeclaracaoCumprimentoCotaAprendizado;

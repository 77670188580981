import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: 2s;
  /* justify-self: center; */

  padding: 10px;

  #formatCapitalize {
    text-transform: capitalize;
  }

  div {
   padding: 5px 10px;
   background: #fff;


   [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
    .site-collapse-custom-collapse .site-collapse-custom-panel {
      /* background: #fff; */
      /* border-radius: 2px; */
      margin-bottom: 24px;
      border: 0;
      /* overflow: hidden; */
    }
  }

  .downloadPDF {
    background: transparent;
    border: 1px solid rgba(0, 150, 255, 0.7);
    display: flex;
    justify-content: center;
    width: 5vw;
    padding: 5px;

    svg {
      color: rgba(0, 150, 255, 0.7);
    }

    &:hover {
      background: rgba(0, 150, 255, 0.7);
      border: 1px solid #fff;
      svg {
        color: #fff;
        font-size: 2rem;
      }
    }
  }

  @media print {
    .downloadPDF {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 40px;

  button {
    display: flex;
    align-items: center;
  }
`

export const Empresa = styled.div`
  border-radius: 5px;
  /* width: 60vw; */
  /* margin: 5vh; */
  display: flex;
  justify-content: center;
  align-self: center;
  /* padding: 10px; */

  /* -moz-box-shadow: 3px 3px 5px 1px #ccc; */
  /* -webkit-box-shadow: 3px 3px 5px 1px #ccc; */
  /* box-shadow: 3px 3px 5px 1px #ccc; */

  /* div {
    align-self: center;
    svg {
      margin-right: 5px;
      color: rgba(0, 180, 255, 0.7);
    }
  } */

  /* #razao {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
  } */
    
  p {
    font-size: 1.2rem;
    align-items: center;
    color: rgba(0, 180, 255, 0.9);

    small {
      font-size: 1rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    /* height: 100vh; */

    div {
      svg {
        margin: 0;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      padding: 0;
    }

    #align {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      padding: 0;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 0;
    /* height: 45vh; */

    div {
      svg {
        margin: 0;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      padding: 0;
    }

    #align {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      padding: 0;
    }
  }

  @media print {
    width: 80vw;
    border: 1px solid #ccc;

    -moz-box-shadow: 0px 0px 0px 0px #ccc;
    -webkit-box-shadow: 0px 0px 0px 0px #ccc;
    box-shadow: 0px 0px 0px 0px #ccc;
  }
`;

export const InfoEmpresa = styled.div`
  align-items: center;
  position: relative;
  border-radius: 5px;
  height: 100%;
  padding: 5px;
  width: 55vw;

  div {

    #info1, #info2, #info3, #info4 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      -moz-box-shadow: 3px 3px 5px 1px #ccc;
      -webkit-box-shadow: 3px 3px 5px 1px #ccc;
      box-shadow: 3px 3px 5px 1px #ccc;
    }

  }

  div {
    padding: 0px;
    margin-top: 30px;

    #header {
      /* background: #ccc; */
      width: 60vw;
      display: flex;
      position: absolute;
      top: -4vh;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px 0;

      button {
        width: 10vw;
        height: 5vh;
        font-size: 1.4rem;
        font-weight: 500;

        &:hover {
          background: rgba(0, 150, 255, 0.9);
        }
      }

      small {
        color: rgba(0, 0, 0, 0.4);
        font-size: 1rem;
      }

    }
  }

  p {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 550;
    margin: 0 5px;
    color: rgba(0, 180, 255, 0.9);
    padding: 5px;

    small {
      font-size: 1rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
      padding: 5px;

      /* background: #ccc; */
      border: 1px dashed rgba(0, 180, 255, 0.1);
      border-radius: 5px;
      margin-top: 2px;
    }

    #grid-2-columns {
      /* margin: -10px 0 0 -30px; */
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* justify-items: center; */
      /* align-items: center; */
    }

    #grid-3-columns {
      margin: -10px 0 0 -30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      /* align-items: center; */
    }

    #grid-4-columns {
      /* margin-top: -1px; */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;

      small {
        display: flex;
        justify-content: center;
      }
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    display: flex;
    justify-content: center;
    align-self: center;


    div {

      #info1 {
        display: flex;
        flex-direction: column;
        width: 65vw;
                /* align-items: center; */

        small {
          width: 55vw;
        }

      }

      #info2 {
        display: flex;
        flex-direction: column;
        width: 65vw;
        /* align-items: center; */

        small {
          width: 55vw;
        }

      }

      #info3 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

        small {
          width: 55vw;
        }

        #grid-4-columns {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          /* margin: -3px 0 0 -10px; */
          align-items: center;

          small {
            width: 15vw;
            margin: 0 5px;
          }
        }

      }

      #info4 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

        small {
          width: 55vw;
        }

      }

    }

    div {
      padding: 10px;
      margin-top: 30px;

      #header {
        display: flex;
        position: absolute;
        top: -4vh;
      }
    }

    p {
      font-size: 0.8rem;
      small {
        width: 24vw;
      }
    }
  }

  @media print {
    width: 80vw;

    div {
      #info1, #info2, #info3, #info4 {
        width: 80vw;
        border: 1px solid #ccc;

        -moz-box-shadow: 0px 0px 0px 0px #ccc;
        -webkit-box-shadow: 0px 0px 0px 0px #ccc;
        box-shadow: 0px 0px 0px 0px #ccc;
      }
    }
  }
`;


export const Users = styled.div`
  display: flex;
  width: 50vw;

  div {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 550;
    margin: 5px;
    color: rgba(0, 180, 255, 0.9);

    -moz-box-shadow: 3px 3px 5px 1px #ccc;
    -webkit-box-shadow: 3px 3px 5px 1px #ccc;
    box-shadow: 3px 3px 5px 1px #ccc;

    small {
      font-size: 1.2rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
      padding: 5px;

      /* border: 1px dashed rgba(0, 180, 255, 0.5); */
      border-radius: 5px;
      margin-top: 2px;
    }

    #name {
      text-transform: capitalize;
    }
  }

  @media print {
    width: 80vw;
  }
`;

export const Credenciamento = styled.div`
  @media print {
    display: none;
  }
`

import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../services/api";

import Button from "../../components/Button";
import TextArea from "../../components/TextArea";

import { Container } from './styles';

function EditItem1({ listinfo }) {

  const formRef = useRef(null);

  async function handleUpdate(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        historico: Yup.string().required(),
        area_negocio: Yup.string().required(),
        missao: Yup.string().required(),
        visao: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { historico, area_negocio, missao, visao, ...rest } = listinfo;

      // dispatch(updateInfoRequest({ ...data, ...rest }))

      await api.put("infoempresa", {...data, ...rest}, {
      }).then(() => {
        toast.success("Informações atualizadas!");
      }).catch(() => {
        toast.error("Erro na atualização das informações!");
      });

    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Form ref={formRef} initialData={listinfo} onSubmit={handleUpdate}>
        <div>
          <label>
            <small>*</small> Histórico da empresa :
            <TextArea name="historico" defaultValue={listinfo.historico} />
          </label>

          <label>
            <small>*</small> Área de Negócio:
            <TextArea name="area_negocio" defaultValue={listinfo.area_negocio} />
          </label>

          <label>
            <small>*</small> Missão:
            <TextArea name="missao" defaultValue={listinfo.missao} />
          </label>

          <label>
            <small>*</small> Visão:
            <TextArea name="visao" defaultValue={listinfo.visao} />
          </label>
        </div>

        <Button>Salvar alterações</Button>
      </Form>
    </Container>
  );
}

export default EditItem1;
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import { FiLogIn } from "react-icons/fi";
import * as Yup from "yup";

import Input from "../../components/Input";
import Button from "../../components/Button";

import logo from "../../assets/logo_cadcti.svg";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_SECTI_1.png";
import logoSecti from "../../assets/BRASÃO_SECTI_PNG-02.png";

import { signInRequest } from "../../store/modules/auth/actions";

import { Container, Content, Background } from "./styles";

function SignIn() {
  const dispatch = useDispatch();
  // const loading = useSelector((state) => state.auth.loading);

  const formRef = useRef(null);

  async function handleSubmit({ email, password }) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Insira um e-mail válido.")
          .required("O e-mail é obrigatório."),
        password: Yup.string().min(8).required("A senha é obrigatória."),
      });

      await schema.validate(
        { email, password },
        {
          abortEarly: false,
        }
      );

      dispatch(signInRequest({ email, password }));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
  return (
    <Container>
      <Content>
        <img src={logo} alt="CADCTI" />

        <h1>Acesse o Sistema</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="email" placeholder="Seu e-mail" />
          <Input
            name="password"
            type="password"
            placeholder="Sua senha secreta"
          />

          {/* <button type="submit">
            {loading ? "Carregando ..." : "Acessar"}
          </button> */}

          <Button type="submit">
            Acessar
          </Button>

          <Link to="/novasenha" type="button">
            Esqueci minha senha
          </Link>
        </Form>

        <Link to="/cadastro" type="button">
          <FiLogIn />
          Faça seu cadastro
        </Link>

        <div id="secti"> 
          <img src={logoSecti} alt="SECTI-AL"  />
        </div>
      </Content>

      <Background />
    </Container>
  );
}

export default SignIn;

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TiArrowBackOutline } from "react-icons/ti"

import history from "../../services/history";

import Input from "../../components/Input";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";

import { Container } from "./styles";

function Item2({ location }) {
  const { state } = location;
  const formRef = useRef(null);

  async function handleNext(item2) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        objetivos_estrategicos: Yup.string().required(),
        produtos_servicos: Yup.string().required(),
        mercado: Yup.string().required(),
        planos_projetos: Yup.string().required(),
      });

      await schema.validate(item2, {
        abortEarly: false,
      });

      history.push("/infoempresa/form3", { ...state, ...item2 });
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Link to="/infoempresa/form1">
        <TiArrowBackOutline />
      </Link>
      <Form ref={formRef} onSubmit={handleNext}>
        <div>
          <label>
            <small>*</small> Objetivos Estratégicos:
            <TextArea
              name="objetivos_estrategicos"
              placeholder="Objetivos Estratégicos da empresa"
            />
          </label>

          <label>
            <small>*</small> Produtos e/ou serviços e seus diferenciais:
            <TextArea
              name="produtos_servicos"
              placeholder="Produtos e/ou serviços e seus diferenciais"
            />
          </label>

          <label>
            <small>*</small> Mercado:
            <Input name="mercado" placeholder="Mercado da empresa" />
          </label>

          <label>
            <small>*</small> Planos/projetos de crescimento/desenvolvimento e expansão:
            <TextArea
              name="planos_projetos"
              placeholder="Planos/projetos de crescimento/desenvolvimento e expansão"
            />
          </label>
        </div>

        <Button>Próximo</Button>
      </Form>
    </Container>
  );
}

export default Item2;

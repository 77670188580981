import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiSend } from "react-icons/fi";
// import { MdLink } from "react-icons/md"
import moment from "moment";

import api from "../../services/api";
import history from "../../services/history";

import Button from "../../components/Button";
// import FileList from "../../components/FileList";

import { Container, InfoEmpresa } from "./styles";
// import { Container, InfoEmpresa, FileList, FileInfo } from "./styles";

function ProfileEmpresa() {
  const profile = useSelector((state) => state.user.profile);

  const [infoEmpresa, setInfoEmpresa] = useState(false);
  // const [uploadFiles, setUploadFiles] = useState([]);

  function handleEdit() {
    history.push("editInfo", infoEmpresa)
  }
  

  useEffect(() => {
    api.get("infoempresa").then((response) => {
      setInfoEmpresa(response.data);
    });

    // api.get("postfile").then((response) => {
    //   setUploadFiles(response.data);
    // });
  }, []);

  return (
    <Container>
      <InfoEmpresa>
        {infoEmpresa ? (
          <div>
            <div id="header">
              <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                {profile.representante ? 
                  <Button onClick={handleEdit}>
                    Editar
                  </Button>
                : ""}
            </div>
           <div id="info1">
              <p>
                Histórico: <small>{infoEmpresa.historico}</small>
              </p>
              <p>
                Área de Negócio: <small>{infoEmpresa.area_negocio}</small>
              </p>
              <p>
                Missão: <small>{infoEmpresa.missao}</small>
              </p>
              <p>
                Visão: <small>{infoEmpresa.visao}</small>
              </p>
              <p>
                Objetivos Estratégicos:{" "}
                <small>{infoEmpresa.objetivos_estrategicos}</small>
              </p>
              <p>
                Produtos e/ou serviços e seus diferenciais:
                <small>{infoEmpresa.produtos_servicos}</small>
              </p>
            </div>

            
            <div id="info2">
                <p>
                  Mercado: <small>{infoEmpresa.mercado}</small>
                </p>

                <p>
                  Planos/projetos de crescimento/desenvolvimento e expansão:{" "}
                  <small>{infoEmpresa.planos_projetos}</small>
                </p>

                <div id="grid-2-columns">
                  <p>
                  Número atual de empregados registrados:{" "}
                    <small>{infoEmpresa.empresagdos_registrados}</small>
                  </p>

                  <p>
                    Número atual de empregados em Pesquisa e/ou Desenvolvimento:{" "}
                    <small>{infoEmpresa.empregrados_pesq_desenv}</small>
                  </p>
                </div>
              </div>

            <div id="info3">
              <p>
                Número de empregados por nível de formação:{" "}
                <div id="grid-4-columns">
                  Ensino Médio: <small>{infoEmpresa.empregados_ensino_medio ? infoEmpresa.empregados_ensino_medio : 0}</small>
                  Técnico: <small>{infoEmpresa.empregados_ensino_tecnico ? infoEmpresa.empregados_ensino_tecnico : 0}</small>
                  Graduação: <small>{infoEmpresa.empregados_ensino_graduacao ? infoEmpresa.empregados_ensino_graduacao : 0}</small>
                  Especialização: <small>{infoEmpresa.empregados_ensino_especializacao ? infoEmpresa.empregados_ensino_especializacao : 0}</small>
                  Mestrado: <small>{infoEmpresa.empregados_ensino_mestrado ? infoEmpresa.empregados_ensino_mestrado : 0}</small>
                  Doutorado: <small>{infoEmpresa.empregados_ensino_doutorado ? infoEmpresa.empregados_ensino_doutorado : 0}</small>
                  Pós-Doutorado: <small>{infoEmpresa.empregados_ensino_pos_doutorado ? infoEmpresa.empregados_ensino_pos_doutorado : 0}</small>
                </div>
              </p>

              <p>
                Premiação/certificado de participação em processo de aceleração
                (entre outros itens que a empresa tenha interesse em apresentar):{" "}
                <small>{infoEmpresa.premiacao_certificado ? "Sim" : "Não"}</small>
                <small>{infoEmpresa.descricao_premio ? infoEmpresa.descricao_premio : ""}</small>
              </p>

              <p>
                Expectativa de contratação para os próximos 05 (cinco) anos:{" "}
                <small>{infoEmpresa.expectativa_contratacao}</small>
              </p>

            </div>
            {infoEmpresa.acesso_polo && 
              <div id="info4">
                {infoEmpresa.justificativa_proposta ? 
                  <>
                    <p>
                      Razões/motivações para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:{" "}
                      <small>{infoEmpresa.justificativa_proposta}</small>
                    </p>
    
                    <p>
                      Especificação técnica da infraestrutura necessária para a ocupação
                      da empresa no Centro de Inovação do Polo Tecnológico:{" "}
                      <small>{infoEmpresa.infraestrutura}</small>
                    </p>
      
                    <p>
                      Qual o espaço físico (m²) necessário para a ocupação da empresa no
                      Centro de Inovação do Polo Tecnológico?: <small>{infoEmpresa.espcao_fisico}</small>
                    </p>
      
                    <p>
                      Qual o período pretendido para a Permissão de Uso do espaço no
                      Centro de Inovação do Polo Tecnológico?: <small>{infoEmpresa.periodo_uso}</small>
                    </p>

                  </>
                : <p>Atualize suas informações para ocupação da empresa no Centro de Inovação do Polo Tecnológico.</p> }
              </div>
            }
          </div>

          
        ) : profile.representante ? (
          <div id="addInfo">
            <h3>Empresa sem dados cadastrados!</h3>
            <Link to="infoempresa/form1">
              <Button>
                <FiSend color={"#fff"} />
                Cadastrar dados da empresa
              </Button>
            </Link>
          </div>
        ) : (
          "Empresa sem dados cadastrados!"
        )}
      </InfoEmpresa>
    </Container>
  );
}

export default ProfileEmpresa;

import React, { useEffect, useState } from "react";

import 'antd/dist/antd.css';
import { Statistic, Row, Col } from 'antd';
import { Spin, Input, Switch, Button } from 'antd';
import Workbook from "react-excel-workbook";
import { toast } from "react-toastify";

import { DownloadOutlined } from '@ant-design/icons';

import { LoadingOutlined } from '@ant-design/icons';

import api from "../../services/api";
// import history from "../../services/history";

import ListCard from "../../components/ListEmpresasCard";
import ListTable from "../../components/ListEmpresasTable";

import { Container, Header } from './styles';

import { useDispatch } from "react-redux";
import { signOut } from "../../store/modules/auth/actions";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



function DashUserAdm() {
  
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useState(true);
  const [search, setSearch] = useState([]);

  const { Search } = Input;

  function onChange(checked) {
    setLayout(checked);
  };

  function handleSearch(e) {
    setSearch(e.target.value);
    setLoading(true);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    function handleSignOut() {
      dispatch(signOut());
    }

    api.get("empresa").then((response) => {
      setEmpresas(response.data);
      setLoading(false);
    }).catch((e) => {
      if (e.response && e.response.status){
        if (e.response.status === 401){ // not authorized
          handleSignOut(); // redirect to login
        }
      }
      if (e.message){
        toast.error(e.message)
      }
    });
  }, [dispatch]);

  // Search
  useEffect(() => {
    api
      .get(`search/?search=${search}`).then((response) => {
        setEmpresas(response.data);
        setLoading(false);
      });
  }, [search]);

  const Download = (
    <Workbook
      filename="Cadastros_Empresas_CADCTI.xlsx"
      element={<Button type="primary" icon={<DownloadOutlined />} loading={loading}>Download</Button>}
    >
      <Workbook.Sheet data={empresas} name="Empresas">
        {/* <Workbook.Column label="Id" value={(row) => row.id} /> */}
        <Workbook.Column label="CNPJ" value={(row) => row.cnpj} />
        <Workbook.Column label="Razao_Social" value={(row) => row.razao_social} />
        <Workbook.Column label="CNAE_Principal" value={(row) => row.cnae_principal} />
        <Workbook.Column label="Atividade_Principal" value={(row) => row.atividade_principal} />
        <Workbook.Column label="CNAE_Secundario" value={(row) => row.cnae_secundario} />
        <Workbook.Column label="Atividade_Secundaria" value={(row) => row.atividade_secundaria} />
        <Workbook.Column label="Natureza_Juridica" value={(row) => row.natureza_juridica} />
        <Workbook.Column label="Porte" value={(row) => row.porte} />
        <Workbook.Column label="Capital_Social" value={(row) => row.capital_social} />
        <Workbook.Column label="Email" value={(row) => row.email} />
        <Workbook.Column label="Telefone" value={(row) => row.telefone} />
      </Workbook.Sheet>
    </Workbook>
  );

  return (
    <Container>
      <Header>
        <Row gutter={40}>
          <Col span={30}>
          { empresas.length === 0 ? 
            <Spin indicator={antIcon} /> 
            :
            <Statistic title="Empresas Cadastradas" value={empresas.length} /> 
          }
          </Col>
        </Row>
          
       {Download}

        <div>
          <Search placeholder="cnpj, email, ok, pendente" loading={loading} onChange={handleSearch} enterButton />
        </div>
        <div>
          <Switch defaultChecked onChange={onChange} />
        </div>
      </Header>
     
     {!layout ? <ListCard empresas={empresas} /> : <ListTable empresas={empresas} /> }
    </Container>
  );
}

export default DashUserAdm;

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TiArrowBackOutline } from "react-icons/ti"
// import { uniqueId } from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox } from "@material-ui/core";

import history from "../../services/history";
// import api from "../../services/api";

import { addInfoempresaRequest } from "../../store/modules/user/actions";

import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import ButtonApp from "../../components/Button";
// import Upload from "../../components/Upload";
// import FileList from "../../components/FileList";

import { Container } from "./styles";

function Item4({ location }) {

  const dispatch = useDispatch();

  const { state } = location;

  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(null);
  const [optionSelect, setOptionSelect] = useState(null);
  const [data, setData] = useState();
  // const [uploadFiles, setUploadFiles] = useState([]);

  const formRef = useRef(null);

  function handleChange (e) {
    const { value } = e.target;
    if (value === "true") {
      setOption(true);
    } else {
      setOption(false);
    }

  };

  function handleChangeSelect (e) {
    const { value } = e.target;
    if (value === "true") {
      setOptionSelect(true);
    } else {
      setOptionSelect(false);
    }
  };

  // async function handleUpload(e) {
  //   Object.keys(e.target.files).forEach(async function (file) {
  //     const upload = {
  //       file: e.target.files[file],
  //       id: uniqueId(),
  //       name: e.target.files[file].name,
  //       progress: 0,
  //       uploaded: false,
  //       error: false,
  //       url: null
  //     }

  //     const data = new FormData();

  //     data.append("file", upload.file);

  //     await api.post("/postfile", data, { 
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json;charset=utf-8"
  //       },
  //       onUploadProgress: e => {
  //         const progess = parseInt(Math.round((e.loaded * 100) / e.total));

  //         updateFile(upload.id, {
  //           progess,
  //         })
  //       }
  //     }).then(response => {
  //       updateFile(upload.id, {
  //         uploaded: true,
  //         id: response.data._id,
  //         name: response.data.files.name,
  //         url: response.data.files.url
  //       });
  //     }).catch(() => {
  //       updateFile(upload.id, {
  //         error: true
  //       });
  //     });
  //   });
  // }


  // function updateFile(id, data) {
  //   setUploadFiles([...uploadFiles, data]);
  // }

  async function handleNext(item4) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        premiacao_certificado: Yup.boolean().required(),
        expectativa_contratacao: Yup.number().required(),
        acesso_polo: Yup.boolean().required(),
        descricao_premio: Yup.string(),
      });

      await schema.validate({...item4, premiacao_certificado: optionSelect, acesso_polo: option}, {
        abortEarly: false,
      });


      if (option === null) {
        toast.warn("Confirme a opção de acesso ao Polo.");
      } else {
        handleClickOpen();
        setData({ ...state, ...item4, premiacao_certificado: optionSelect, declaracao_termo: true, acesso_polo: option }); 
      }

      
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const Pop = () => {
    return (<Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"DECLARAÇÃO"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Declaro que li e entendi os termos da “Consulta Pública 001/2020” e que as informações declaradas são verdadeiras.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={handleClose} color="primary">
                    Não
                  </Button> */}
                  <Checkbox name="check"
                    value="checkedA"
                    onChange={handleConfirmation}
                  /> <h4>Li e concordo comos termos acima</h4>
                </DialogActions>
              </Dialog>)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirmation() {

    if (option) {
      history.push("/infoempresa/form5", { ...state, ...data });
    } else {
      dispatch(addInfoempresaRequest(data));
    }
  }

  return (
    <Container>
      <Link to="/infoempresa/form3">
        <TiArrowBackOutline />
      </Link>
      <Pop/>
      <Form ref={formRef} onSubmit={handleNext}>
        <div>
          <label>
            <div id="premio">
              <label><small>*</small> Recebeu premiação/certificado de participação em processo de aceleração ? 
                <p>(entre outros itens que a empresa tenha interesse em apresentar)</p>
              </label>
              <section>
                <input type="radio" value={true} name="premiacao_certificado" onChange={handleChangeSelect}/> Sim
                <input type="radio" value={false} name="premiacao_certificado" onChange={handleChangeSelect}/> Não
              </section>
            </div>

          </label>
          
          <label>
            <small>*</small> Expectativa de contratação para os próximos 05 (cinco) anos:
              <Input
                type="number"
                name="expectativa_contratacao"
                placeholder="Expectativa de contratação para os próximos 05 (cinco) anos"
              />
          </label>
        </div>
        {/* {optionSelect ? <Upload onUpload={handleUpload}/> : ""} */}

        {optionSelect ? <TextArea className="premio" name="descricao_premio" placeholder="Descreva sobre a premiação/certificado de participação. (Caso tenha links de certificados favor incluir na descrição.)" />  : ""}

        {/* { !!uploadFiles.length && (
          <FileList files={uploadFiles} />
        ) } */}

        <div>
          <div id="check">
            <label><small>*</small>  Deseja acesso ao Polo?</label>
              <input type="radio" value={"true"} name="acesso_polo" onChange={handleChange}/> Sim
              <input type="radio" value={"false"} name="acesso_polo" onChange={handleChange}/> Não
          </div>
        </div>

        <ButtonApp>Confirmar</ButtonApp>
      </Form>
    </Container>
  );
}

export default Item4;


import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";

import { Container, Error } from "./styles";

export default function TextArea({ name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} data-testid="input-container">
      <textarea ref={inputRef} {...rest} />
      {error && (
        <Error id="error">
          <FiAlertCircle size={15} />
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
}

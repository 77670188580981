import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import { FiLogIn } from "react-icons/fi";
import * as Yup from "yup";
import { toast } from "react-toastify";

import history from "../../services/history";
import api from "../../services/api";

import Input from "../../components/Input";
import Button from "../../components/Button";

import logo from "../../assets/logo_cadcti.svg";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_White_SECTI.png";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_SECTI_1.png";
import logoSecti from "../../assets/BRASÃO_SECTI_PNG-02.png";


import { Container, Content, Background } from './styles';

function ForgotPasword() {
  const formRef = useRef(null);

  async function handleSubmit(email) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Insira um e-mail válido.")
          .required("O e-mail é obrigatório."),
      });

      await schema.validate(email, {
        abortEarly: false,
      });

      try {
        const response = await api.patch("forgotpassword", email);

        toast.success(`${response.data.message}`);

        history.push("/login", response.data);
      } catch (error) {
        toast.error(`${error.response.data.error}`);
      };

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
    
  return (
    <Container>
      <Content>
        <img src={logo} alt="CADCTI" />

         <h1>Recuperar acesso ao sistema</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="email" placeholder="Seu email cadastrado" />
            
            <Button type="submit">
              Receber nova senha
            </Button>
          </Form>

          <Link to="/login" type="button">
            <FiLogIn />
              Voltar
          </Link>

          <div id="secti">
            <img src={logoSecti} alt="SECTI-AL" />
          </div>
        </Content>

        <Background />
    </Container>
  );
}

export default ForgotPasword;

import styled from "styled-components";

export const Wrapper = styled.div`
  /* height: 100vh;
  width: 70vw; */
  /* max-height: 300%; */
  /* background: linear-gradient(-30deg, #5ce1e6, #5271ff); */
  /* background: rgba(0, 170, 170, 0.3); */

  /* #sidebar {
    display: flex;
  } */

  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 30vw;
  max-width: 390px;
`;

export const Background = styled.div`
  flex: 1;
  /* background: linear-gradient(
    -30deg,
    rgba(0, 170, 170, 0.2),
    rgba(0, 170, 170, 0.9)
  ); */
  /* background-size: cover; */
`;

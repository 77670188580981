import React from 'react';
import { Table, Space, Tag } from 'antd';
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import crypto from "crypto";

import { Container } from './styles';

function ListEmpresasTable({ empresas }) {
  
  empresas.map(function(e){
    e.key = e._id;
    return e;
  });

  function getCredenciamento(info_empresa){
    let status = 'Sem credenciamento';
    let color = 'geekblue';

    if (info_empresa && info_empresa.credentialing){
      status = 'Em andamento';
      color = 'yellow';

      // se o termo da etapa final foi aceito então o credenciamento foi completado
      if (info_empresa.credentialing.ciente_condicoes_edital === true){
        status = 'Concluído';
        color = 'green';
      }
    }

    return {status: status, color: color}
  }

  function cadastroSorter(a, b){
    return a.info_empresa ?  1 : -1;
  }

  function nomeSorter(a, b){
    return a.razao_social.localeCompare(b.razao_social)
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'razao_social',
      key: 'razao_social',
      render: text => <strong>{text.toUpperCase()}</strong>,
      sorter: nomeSorter
    },
    {
      title: 'Atividade Principal',
      dataIndex: 'atividade_principal',
      key: 'atividade_principal',
    },
    {
      title: 'Cadastro',
      dataIndex: 'info_empresa',
      key: 'info_empresa',
      render: info_empresa => (
        <>
          {info_empresa ? 
            <Tag color="green" key={crypto.randomBytes(2).toString('hex')}>
              <small>Cadastro Realizado</small>
            </Tag>
            : 
            <Tag color="red" key={crypto.randomBytes(2).toString('hex')}>
              <small>Cadastro Incompleto</small>
            </Tag>
          }
        </>
      ),
      sorter: cadastroSorter
    },
    {
      title: 'Credenciamento',
      dataIndex: 'info_empresa',
      key: 'info_empresa',
      filters: [
        {
          text: 'Concluído',
          value: 'Concluído',
        },
        {
          text: 'Em andamento',
          value: 'Em andamento',
        },
        {
          text: 'Sem credenciamento',
          value: 'Sem credenciamento',
        },
        {
          text: 'Com credenciamento',
          value: 'Com credenciamento',
        },
      ],
      filterMultiple: false,
      render: info_empresa => (
        <>
          { 
            <Tag color={getCredenciamento(info_empresa).color} >
              <small>{getCredenciamento(info_empresa).status}</small>
            </Tag>
          }
        </>
      ),
      onFilter: function(value, record){
        // console.log(value) // Com Credenciamento, Sem Credenciamento, Em Andamento, etc..
        // console.log(record.info_empresa)

        if (value === "Sem credenciamento"){
          if (typeof record.info_empresa === 'undefined'){
            return record;
          }
        }

        if (value === "Com credenciamento"){
          if (typeof record.info_empresa !== 'undefined'){
            return record;
          }
        }

        if (value === "Em andamento"){
          if (typeof record.info_empresa !== 'undefined'){
            if (typeof record.info_empresa.credentialing !== 'undefined'){
              if (typeof record.info_empresa.credentialing.ciente_condicoes_edital !== 'undefined'){
                if (record.info_empresa.credentialing.ciente_condicoes_edital === null){
                  return record;
                }
              }
            }
          }
        }
  
        if (value === "Concluído"){
          if (typeof record.info_empresa !== 'undefined'){
            if (typeof record.info_empresa.credentialing !== 'undefined'){
              if (typeof record.info_empresa.credentialing.ciente_condicoes_edital !== 'undefined'){
                if (record.info_empresa.credentialing.ciente_condicoes_edital === true){
                  return record;
                }
              }
            }
          }
        }
      },
    },
    {
      title: 'Abrir',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Space size="middle">
          <Link to={`office/${id}`}>
            <FiEdit />
          </Link>
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    footerData = <div
    style={{textAlign: 'right', fontSize: '20px', color: '#1b84c9'}}
      >Resultados: <strong>{extra.currentDataSource.length}</strong>
      </div>
  };

  let footerData = <div
    style={{textAlign: 'right', fontSize: '20px', color: '#1b84c9'}}
      >Resultados: <strong>{empresas.length}</strong>
    </div>

  const defaultFooter = () => {
    return footerData
  }

  return (
    <Container>
      <Table footer={defaultFooter} columns={columns} dataSource={empresas} onChange={onChange} pagination={{ pageSize: 6 }} />
    </Container>
  );

}

export default ListEmpresasTable;

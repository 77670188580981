import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 10vw;
    /* max-width: 150px; */
  }

  h1 {
    font-size: 1.9rem;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
    /* margin: 5px; */
  }

  div {
    display: grid;
    grid-template-columns: 0fr 0fr;
    justify-content: center;
  }

  form {
    margin: 5px;
    width: 75vw;
    text-align: center;

    padding: 10px;

    border: 1px solid rgba(0, 170, 170, 0.1);

    p {
      font-size: 1.4rem;
      color: #5271ff;
      margin-top: 10px;
    }

    #nome {
      width: 70.7vw;
    }

    input {
      height: 44px;
      padding: 5px;
      width: 35vw;

      margin: 5px;

      &::placeholder {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    .error {
      border: 2px solid #f64c75;
    }

    .success {
      border: 2px solid #16fb80;
    }

    #empresa {
      display: none;
    }

    span {
      color: #f64c75;
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
    }

    a {
      display: block;
      color: #333;
      margin-top: 15px;
      font-size: 1.3rem;
      opacity: 0.8;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }

    button {
      width: 20vw;
      margin: 20px 0px 0 36%;
    }
  }

  .plus {
    width: 10vw;
    position: relative;
    top: -10vh;
    left: 25%;
    background: transparent;
    color: #5271ff;
    font-size: 1.2rem;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: ${shade(0.09, "#5271ff")};
    }
  }

  #cancel {
    position: relative;
    left: 20%;
    width: 20vw;
    background: #f64c75;

    margin-bottom: 10px;

    svg {
      margin-right: 7px;
    }

    &:hover {
      background: ${shade(0.05, "#f64c75")};
    }
  }

  > a {
    color: #5271ff;
    font-size: 1.6rem;
    opacity: 0.8;
    text-decoration: none;
    transition: background 0.2s;

    position: relative;
    right: 20%;
    top: -20%;

    margin-top: -45px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: ${shade(0.09, "#5271ff")};
    }
  }

  @media (max-width: 700px) {
  
    img {
      width: 20vw;
    }

    form {

      #nome {
        width: 73vw;
      }
      
      button {
        width: 30vw;
        margin: 20px 0px 0 25%;
      }
    }

    #cancel {
      width: 30vw;
    }
  }
`;
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  width: 68vw;
  // height: 100vh;
`;

export function updateProfileRequest(data) {
  return {
    type: "@user/UPDATE_PROFILE_REQUEST",
    payload: { data },
  };
}

export function updateProfileSuccess(user) {
  return {
    type: "@user/UPDATE_PROFILE_SUCCESS",
    payload: { user },
  };
}

export function updateProfileFailure() {
  return {
    type: "@user/UPDATE_PROFILE_FAILURE",
  };
}

export function addInfoempresaRequest(data) {
  return {
    type: "@user/ADD_INFOEMPRESA_REQUEST",
    payload: { data },
  };
}

export function addInfoempresaSuccess(profile) {
  return {
    type: "@user/ADD_INFOEMPRESA_SUCCESS",
    payload: { profile },
  };
}

export function addInfoempresaFailure() {
  return {
    type: "@user/ADD_INFOEMPRESA_REQUEST",
  };
}

export function updateInfoempresaRequest(profile) {
  return {
    type: "@user/UPDATE_INFOEMPRESA_REQUEST",
    payload: { profile },
  };
}

export function updateInfoempresaSuccess(profile) {
  return {
    type: "@user/UPDATE_INFOEMPRESA_SUCCESS",
    payload: { profile },
  };
}

export function updateInfoempresaFailure() {
  return {
    type: "@user/UPDATE_INFOEMPRESA_REQUEST",
  };
}

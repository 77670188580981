import React, { useRef, useState } from "react";
// import { useSelector } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import { uniqueId } from "lodash";

import api from "../../services/api";

import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
// import Upload from "../../components/Upload";
// import FileList from "../../components/FileList";

import { Container } from './styles';

function EditItem4({ listinfo }) {
  // const { token } = useSelector((state) => state.auth);
  
  const [optionSelect, setOptionSelect] = useState(null);
  const [option, setOption] = useState(null);
  // const [uploadFiles, setUploadFiles] = useState([]);

  const formRef = useRef(null);

  function handleChange (e) {
    const { value } = e.target;
    if (value === "true") {
      setOption(true);
    } else {
      setOption(false);
    }

  };

  function handleChangeSelect (e) {
    const { value } = e.target;
    if (value === "true") {
      setOptionSelect(true);
    } else {
      setOptionSelect(false);
    }
  };

  // function handleUpload(e) {
  //   Object.keys(e.target.files).forEach(async function (file) {
  //     const upload = {
  //       file: e.target.files[file],
  //       id: uniqueId(),
  //       name: e.target.files[file].name,
  //       progress: 0,
  //       uploaded: false,
  //       error: false,
  //       url: null
  //     }

  //     const data = new FormData();

  //     data.append("file", upload.file);

  //     await api.post("postfile", data, { 
  //       headers: { 
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       onUploadProgress: e => {
  //         const progess = parseInt(Math.round((e.loaded * 100) / e.total));

  //         updateFile(upload.id, {
  //           progess,
  //         })
  //       }
  //     }).then(response => {
  //       updateFile(upload.id, {
  //         uploaded: true,
  //         id: response.data._id,
  //         name: response.data.files.name,
  //         url: response.data.files.url
  //       });
  //     }).catch(() => {
  //       updateFile(upload.id, {
  //         error: true
  //       });
  //     });
  //   });
  // };

  // function updateFile(id, data) {
  //   setUploadFiles([...uploadFiles, data]);
  // };

  async function handleUpdate(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        // premiacao_certificado: Yup.boolean().required(),
        expectativa_contratacao: Yup.number().required(),
        // acesso_polo: Yup.boolean(),
        descricao_premio: Yup.string(),
      });

      await schema.validate({...data}, {
        abortEarly: false,
      });

      if (!listinfo.acesso_polo && option === null) {
        toast.warn("Confirme a opção de acesso ao Polo.");
      } else {
        const { 
          premiacao_certificado, 
          expectativa_contratacao, 
          acesso_polo, 
          descricao_premio, 
          ...rest 
        } = listinfo;

        await api.put("infoempresa", { 
          premiacao_certificado: !premiacao_certificado ? optionSelect : premiacao_certificado, 
          acesso_polo: !acesso_polo ? option : acesso_polo,
          ...data,
          ...rest }, {
        }).then(() => {
          toast.success("Informações atualizadas!");
        }).catch(() => {
          toast.error("Erro na atualização das informações!");
        });
      }

    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }


  return (
    <Container>
      <Form ref={formRef} initialdata={listinfo} onSubmit={handleUpdate}>
        <div>
          <label>
            <div id="premio">
              <label><small>*</small> Recebeu premiação/certificado de participação em processo de aceleração ? 
                <p>(entre outros itens que a empresa tenha interesse em apresentar)</p>
              </label>
              {listinfo.descricao_premio ? <p>Sim</p>   
              : <section>
                  <input type="radio" value={true} name="premiacao_certificado" onChange={handleChangeSelect}/> Sim
                  <input type="radio" value={false} name="premiacao_certificado" onChange={handleChangeSelect}/> Não
                </section>
              }
            </div>
          </label>
          
          <label>
            <small>*</small> Expectativa de contratação para os próximos 05 (cinco) anos:
              <Input
                type="number"
                name="expectativa_contratacao"
                defaultValue={listinfo.expectativa_contratacao}
              />
          </label>
        </div>

        {/* {optionSelect ? <Upload onUpload={handleUpload} /> : ""} */}

        {/* { !!uploadFiles.length && (
          <FileList files={uploadFiles} />
        )} */}

        {listinfo.descricao_premio ? 
          <>
            <label>Descrição sobre o premio</label> 
            <TextArea defaultValue={listinfo.descricao_premio} className="premio" name="descricao_premio" placeholder="Descreva sobre o premio." />  
          </>
        : ""}
        {optionSelect ? <TextArea className="premio" name="descricao_premio" placeholder="Descreva sobre a premiação/certificado de participação. (Caso tenha links de certificados favor incluir na descrição.)" />  : ""}



        {!listinfo.acesso_polo && 
          <div>
            <div id="check">
              <label><small>*</small>  Deseja acesso ao Polo?</label>
                <input type="radio" value={"true"} name="acesso_polo" onChange={handleChange}/> Sim
                <input type="radio" value={"false"} name="acesso_polo" onChange={handleChange}/> Não
            </div>
          </div>
        }

        <Button>Salvar alterações</Button>
      </Form>
    </Container>
  );
}

export default EditItem4;
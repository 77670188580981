import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { TiArrowBackOutline } from "react-icons/ti"

import history from "../../services/history";

import Input from "../../components/Input";
import ButtonApp from "../../components/Button";

import { Container } from "./styles";

function Item3({ location }) {
  const { state } = location;

  const formRef = useRef(null);

  async function handleNext(item3) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        empresagdos_registrados: Yup.number().required(),
        empregrados_pesq_desenv: Yup.number().required(),
        empregados_ensino_medio: Yup.number().required(),
        empregados_ensino_tecnico: Yup.number().required(),
        empregados_ensino_graduacao: Yup.number().required(),
        empregados_ensino_especializacao: Yup.number().required(),
        empregados_ensino_mestrado: Yup.number().required(),
        empregados_ensino_doutorado: Yup.number().required(),
        empregados_ensino_pos_doutorado: Yup.number().required(),
      });

      await schema.validate(item3, {
        abortEarly: false,
      });

      history.push("/infoempresa/form4", { ...state, ...item3 });
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  

  return (
    <Container>
      <Link to="/infoempresa/form2">
        <TiArrowBackOutline />
      </Link>
      <Form ref={formRef} onSubmit={handleNext}>
        <div>
            <label>
              <small>*</small> Número atual de empregados registrados:
              <Input
                name="empresagdos_registrados"
                type="number"
                placeholder="0"
                // defaultValue={0}
              />
            </label>

            <label>
              <small>*</small> Número atual de empregados em Pesquisa e/ou Desenvolvimento:
              <Input
                name="empregrados_pesq_desenv"
                type="number"
                // defaultValue={0}
              />
            </label>
          {/* <div id="separador">
            <label>
              <small>*</small> Faturamento anual:
              <div>
                <Input name="faturamento_2020_1"   placeholder="2020.1 -> R$XX.XX" />
                <Input name="faturamento_2019"  placeholder="2019 -> R$XX.XX" />
                <Input name="faturamento_2018" placeholder="2018 -> R$XX.XX" />
                <Input name="faturamento_2017" placeholder="2017 -> R$XX.XX" />
              </div>
            </label>
          </div> */}
          
            <label>
              <small>*</small> Número de empregados por nível de formação:
              <div id="formacao">
                <strong>Ensino Médio:</strong>
                <Input
                  name="empregados_ensino_medio"
                  // defaultValue={0}
                  type="number"
                />
                <strong>Técnico:</strong>
                <Input name="empregados_ensino_tecnico" type="number"/>
                <strong>Graduação:</strong>
                <Input
                  name="empregados_ensino_graduacao"
                  // defaultValue={0}
                  type="number"
                />
                <strong>Especialização:</strong>
                <Input
                  name="empregados_ensino_especializacao"
                  // defaultValue={0}
                  type="number"
                />
                <strong>Mestrado:</strong>
                <Input
                  name="empregados_ensino_mestrado"
                  // defaultValue={0}
                  type="number"
                />
                <strong>Doutorado:</strong>
                <Input
                  name="empregados_ensino_doutorado"
                  // defaultValue={0}
                  type="number"
                />
                <strong>Pós-Doutorado:</strong>
                <Input
                  name="empregados_ensino_pos_doutorado"
                  // defaultValue={0}
                  type="number"
                />
              </div>
            </label>

        </div>

        <ButtonApp>Próximo</ButtonApp>
      </Form>
    </Container>
  );
}

export default Item3;
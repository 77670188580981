import styled from "styled-components";

export const Container = styled.div`
  input {
    border: 2px solid rgba(0, 170, 170, 0.3);
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;

    &:focus {
      background: rgba(0, 170, 170, 0.1);
    }
  }
`;

export const Error = styled.div`
  height: 20px;
  margin-left: 16px;

  /* padding-bottom: 5px; */
  svg {
    color: #f64c75;
    margin-right: 5px;
  }
  span {
    text-align: center;

    width: 8vw;
    font-size: 0.8rem;
    color: #f64c75;
  }
`;

import React from 'react';
import { Card } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { MdOpenInNew } from "react-icons/md"

import { Container } from './styles';

function DocumentsUtils() {
  return (
    <Container>
      <Card 
        title="Documentos Úteis"
        className="card"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2FEdital%20de%20Credenciamento%2001_2021_SECTI.pdf?alt=media&token=ce2edeab-b425-45f7-a851-98291c77a387"
        >
          <Card type="inner" >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <FilePdfFilled style={{ fontSize: "2rem", marginRight: "10px" }} />
                <text>Edital de Credenciamento</text>
              </div>
              <MdOpenInNew />

            </div>
          </Card>
        </a>
       
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2FModelo%20-%20Atendimento%20DCCA.pdf?alt=media&token=ad6a2ce5-417a-4809-a2ba-361785774593"
        >
          <Card 
            style={{ marginTop: 16 }}
            type="inner" 
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <FilePdfFilled style={{ fontSize: "2rem", marginRight: "10px" }} />
                <text>Modelo - Atendimento DCCA</text>
              </div>
              <MdOpenInNew />

            </div>
          </Card>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2FModelo%20-%20Declarac%CC%A7a%CC%83o%20de%20que%20na%CC%83o%20emprega%20menor.pdf?alt=media&token=54aa67fa-6c32-456d-8890-89b34072e5d8"
        >
          <Card 
            style={{ marginTop: 16 }}
            type="inner" 
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <FilePdfFilled style={{ fontSize: "2rem", marginRight: "10px" }} />
                <text>Modelo - Declaração de que não emprega menor</text>
              </div>
              <MdOpenInNew />

            </div>
          </Card>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2FModelo%20-%20Isenc%CC%A7a%CC%83o%20DCCA.pdf?alt=media&token=ddf38199-3737-4ed0-8909-9cc7a14162f5"
        >
          <Card 
            style={{ marginTop: 16 }}
            type="inner" 
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <FilePdfFilled style={{ fontSize: "2rem", marginRight: "10px" }} />
                <text>Modelo - Isenção DCCA</text>
              </div>
              <MdOpenInNew />

            </div>
          </Card>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2Fmanual%20de%20plano%20de%20atividades.pdf?alt=media&token=99344993-ff98-4b99-ba02-8d790235f167"
        >
          <Card 
            style={{ marginTop: 16 }}
            type="inner" 
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <FilePdfFilled style={{ fontSize: "2rem", marginRight: "10px" }} />
                <text>Manual para Realização do plano de atividade a serem executadas no CIPT</text>
              </div>
              <MdOpenInNew />

            </div>
          </Card>
        </a>
      </Card>
    </Container>
  );
}

export default DocumentsUtils;

import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
    // headers: {
    //   'Access-Control-Allow-Origin' : 'https://cadcti.al.gov.br',
    //   // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }
});

export default api;

import React from "react";
import { useSelector } from "react-redux";

import DashUserEmpresa from "../../components/DashUserEmpresa";
import DashUserAdm from "../../components/DashUserAdm";

function Dashboard() {
  const profile = useSelector((state) => state.user.profile);

  return (
    <div>
      { profile.empresa ? <DashUserEmpresa /> : <DashUserAdm /> }
    </div>
  );
}

export default Dashboard;

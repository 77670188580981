import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { Result, Button } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import logo from "../../assets/logo_cadcti.svg";

import { Container } from "./styles";

function Confirm() {
  return (
    <Container>
      <div>
        <img src={logo} alt="CADCTI" />

        <Result
          status="success"
          title={
            <h4>Cadastro Realizado com Sucesso!</h4>
          }
          icon={
            <CheckCircleTwoTone style={{ fontSize: "5rem" }} />
          }
          extra={[
            <Link to="/login" type="button">
              <Button type="primary" key="console">
                <FiLogIn />
                Faça Aqui seu login 
              </Button>
            </Link>
          ]}
        />
      </div>
    </Container>
  );
}

export default Confirm;

import styled from 'styled-components';
import { shade } from "polished";

import background from "../../assets/foto_polo_filter.jpg";

export const Container = styled.div`
    height: 100vh;

    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  /* margin-top: -15vh; */

  width: 100%;
  max-width: 550px;

  img {
    width: 10vw;
    max-width: 200px;
  }

  #secti {
    img {
      height: 120px;
      width: 120px;
    }
    display: flex;
    place-content: center;
    margin-top: 50px;
    margin-bottom: -100px;
  }

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
  }

  form {
    margin: 25px 0;
    width: 340px;
    text-align: center;
    font-size: 1rem;
    color: #f64c75;

    input {
      height: 44px;
      padding: 0 15px;
      width: 100%;
      margin: 0 0 10px;

      &::placeholder {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    span {
      color: #f64c75;
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
    }

    button {
      margin: 5px 0 0;
      height: 46px;
      width: 100%;
      background: #5271ff;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${shade(0.05, "#5271ff")};
      }
    }

    a {
      display: block;
      color: #333;
      margin-top: 15px;
      font-size: 1.3rem;
      opacity: 0.8;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  > a {
    color: #5271ff;
    font-size: 1.6rem;
    opacity: 0.8;
    text-decoration: none;
    transition: background 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: ${shade(0.05, "#5271ff")};
    }
  }

  @media (max-width: 700px) {
    display: flex;
    /* justify-content: top; */
    margin-top: 0;
    
    img {
      width: 30vw;
      
    }

    button {
      margin: 20px 0 0;
      width: 80vw;
    }
  
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${background}) no-repeat;
  background-size: cover;

  -moz-box-shadow: 1px 3px 5px 2px #ccc;
  -webkit-box-shadow: 1px 3px 5px 2px #ccc;
  box-shadow: 1px 3px 5px 2px #ccc;
`;

import React from "react";
import PropTypes from "prop-types";

// import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";

import { Background } from "./styles";

import { Layout } from 'antd';

const { Content, Sider } = Layout

// import { Container, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap-icons/font/bootstrap-icons.css";

// export default function DefaultLayout({ children }) {

//   return (
//     <Container fluid>
//       <Row>
//         <Col md="3" className="p-0">
//           <Sidebar />
//         </Col>
//         <Col md="9" className="p-0">
//           <Background>{children}</Background>
//         </Col>
//       </Row>
//     </Container>
//   );
// }

export default function DefaultLayout({ children }) {
  return (
    <Layout>
      {/* <Header /> */}
      <Sider
        collapsedWidth={80}
        theme="light"
        breakpoint="sm"
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Content>
          <Background>{children}</Background>
        </Content>
      </Layout>
    </Layout>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

import React, { useRef, useState } from "react";
// import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import { FiLogIn } from "react-icons/fi";
import * as Yup from "yup";
import { toast } from "react-toastify";
import jsonp from "jsonp";

import history from "../../services/history";
import api from "../../services/api";

import Input from "../../components/Input";
import Button from "../../components/Button";
import InputM from "../../components/InputMask";

import logo from "../../assets/logo_cadcti.svg";

import { Container } from "./styles";

function SignUp() {
  // const loading = useSelector((state) => state.auth.loading);

  // const [cnpjUser, setCnpjUser] = useState([]);
  const [dataEmpresa, setDataEmpresa] = useState({});

  const formRef = useRef(null);

  function checkTelefone(e){
    if (e.target.value.length !== 0 && e.target.value.length < 16){
      e.target.value = e.target.value.replace(/[[ a-zA-Z!@#$%^&*+=._~\]]/g, m => '');

      if (e.target.value[0] !== "("){
        let old_string = e.target.value;
        e.target.value = '('.concat(old_string);
      }

      if (e.target.value.length >2){
        if (e.target.value[3] !== ")"){
          let first_part = e.target.value[0].concat(e.target.value[1]).concat(e.target.value[2]).concat(')');
          let second_part = e.target.value.slice(5);
          e.target.value = first_part.concat(second_part);
        }

        if (e.target.value[5] !== " "){
          let first_part = e.target.value[0].concat(e.target.value[1]).concat(e.target.value[2]).concat(') ');
          let second_part = e.target.value.slice(4);
          e.target.value = first_part.concat(second_part);
        }
      }

      if (e.target.value.length > 9){
        let first_part = e.target.value.slice(0, 10);
        let second_part = e.target.value.slice(11);
        if (e.target.value[10] !== "-" && e.target.value[9] !== "-"){
          e.target.value = first_part.concat('-').concat(second_part);
        }
      }
    }

  }

  function checkCnpj(e) {
    const cnpj = e.target.value.replace(/\D/g, "");

    if (cnpj.length === 14) {
      // setCnpjUser(cnpj);
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${cnpj}`,
        null,
        (err, data) => {
          try{
            if (data.status === "ERROR") {
              toast.error(`${data.message}`);
            } else {
              const {
                nome,
                telefone,
                email,
                atividade_principal,
                atividades_secundarias,
                porte,
                natureza_juridica,
                capital_social,
                fantasia
              } = data;
              setDataEmpresa({
                razao_social: nome,
                telefone: telefone,
                email,
                cnae_principal: atividade_principal[0].code,
                atividade_principal: atividade_principal[0].text,
                cnae_secundario: atividades_secundarias[0].code,
                atividade_secundaria: atividades_secundarias[0].text,
                porte,
                natureza_juridica,
                capital_social,
                fantasia
              });
            }
          }catch(e) {
            console.log("falha ao validar cnpj: " + e.message);
            console.log("falha ao validar cnpj: " + err.message);
            // toast.error(`Erro ao validar cnpj: ${e.message}`);
          }
        }
      );
    }
  }

  async function handleSubmit(data) {
    data.telefone = data.telefone.replace(/\D/g, ""); 
    data.cnpj = data.cnpj.replace(/\D/g, "");
    data.cnae_principal = data.cnae_principal.replace(/\D/g, "");
    data.cnae_secundario = data.cnae_secundario.replace(/\D/g, "");

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        razao_social: Yup.string()
          .required("Razão social é obrigatório.")
          .max(115, 'Razão social precisa conter no maxímo 115 caracteres.'),
        cnpj: Yup.string()
          .required("CNPJ é obrigatório.")
          .min(14, "CNPJ precisa conter 14 dígitos."),
        telefone: Yup.string()
        .max(20, 'Máximo 20 dígitos')
        .required("Telefone é obrigatório."),
        email: Yup.string()
          .email("Insira um e-mail válido.")
          .max(255, "Máximo 255 caracteres")
          .required("O e-mail é obrigatório."),
        cnae_principal: Yup.string()
          .min(7, 'Mínimo 7 dígitos')
          .max(7, 'Máximo 7 dígitos')
          .required("Obrigatório."),
        cnae_secundario: Yup.string()
          .min(7, 'Mínimo 7 dígitos')
          .max(7, 'Máximo 7 dígitos')
          .required("Obrigatório."),
        capital_social: Yup.string()
          .required("Capital Social é obrigatório."),
        natureza_juridica: Yup.string()
          .required("Natureza Jurídica é obrigatório."),
        porte: Yup.string()
          .required("Porte da empresa é obrigatório."),
        atividade_principal: Yup.string()
          .required("Atividade Principal é obrigatório."),
        atividade_secundaria: Yup.string()
          .required("Atividade Secundaria é obrigatório."),
        fantasia: Yup.string()
          .max(55, 'Nome fantasia da empresa precisa conter no maxímo 55 caracteres.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      try {
        const response = await api.post("empresa", data);

        history.push("/addusers", response.data);
      } catch (error) {
        toast.error(`${error.response.data.error}`);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <img src={logo} alt="CADCTI" />

      <h1>Cadastro da Empresa</h1>

      <div>
        <Form ref={formRef} initialData={dataEmpresa} onSubmit={handleSubmit}>
          <InputM
            name="cnpj"
            placeholder="CNPJ da empresa"
            mask="99.999.999/9999-99"
            onChange={checkCnpj}
          />
          <Input name="razao_social" placeholder="Razão social da empresa" />
          <Input id="fantasia" name="fantasia" placeholder="Nome fantasia" />
          <Input onKeyUp={checkTelefone} name="telefone" placeholder="Telefone de contato da empresa" />
          <Input name="email" placeholder="E-mail da empresa" />

          <div id="grid">
            <Input
              id="num"
              name="cnae_principal"
              placeholder="CNAE"
            />
            <Input id="ativ" name="atividade_principal" placeholder="Atividade principal" />
          </div>

          <div id="grid">
            <Input
              id="num"
              name="cnae_secundario"
              placeholder="CNAE"
            />
            <Input id="ativ" name="atividade_secundaria" placeholder="Atividade secundaria" />
          </div>

          <Input name="porte" placeholder="Porte da empresa" />
          <Input name="natureza_juridica" placeholder="Natureza jurídica da empresa" />
          
          {/* <div id="grid">
            
            
          </div> */}
          <Input id="num" name="capital_social" placeholder="Capital Social da empresa" />
          <Input id="ativ" name="site" placeholder="Site da empresa" />

          
          <Button type="submit">Confirmar</Button>
        </Form>
      </div>
      <Link to="/login" type="button">
        <FiLogIn />
        Já tenho login
      </Link>
    </Container>
  );
}

export default SignUp;

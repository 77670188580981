import styled from "styled-components";
import { shade } from "polished";

// import background from "../../assets/background-cadcti.png";
// import background from "../../assets/Foto_polo.jpg";
import background from "../../assets/foto_polo_filter.jpg";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 100%;
  max-width: 550px;

  img {
    width: 10vw;
    max-width: 200px;
  }

  #secti {
    img {
      height: 120px;
      width: 120px;
    }

    display: flex;
    place-content: center;
    margin-top: 50px;
    margin-bottom: -150px;
  }

  #disable {
    opacity: 0.3;
  }

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
  }

  button {
    margin: 20px 0 0;
    width: 30vw;
    max-width: 400px;

    background: #5271ff;

    &:hover {
      background: ${shade(0.07, "#5271ff")};
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    display: flex;
    justify-content: top;
    margin: 0;
    
    img {
      width: 27vw;
      
    }

    button {
      margin: 20px 0 0;
      width: 80vw;
    }
  
  }
`;

export const Background = styled.div`
  flex: 1;
  position: relative;
  background: url(${background}) no-repeat;
  background-size: cover;

  -moz-box-shadow: 1px 3px 5px 2px #ccc;
  -webkit-box-shadow: 1px 3px 5px 2px #ccc;
  box-shadow: 1px 3px 5px 2px #ccc;
`;

import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  width: 68vw;
  display: flex;
  flex-direction: column;

  padding: 10px;

  position: relative;
  left: 1.8%;

  a {
    color: rgba(0, 170, 170, 0.9);
    transition: background 0.2s;

    svg {
      font-size: 2.5rem;
    }

    &:hover {
      color: ${shade(0.09, "rgba(0, 170, 170, 0.9)")};
      svg {
      font-size: 2.7rem;
    }
    }
  }

  form {
    margin-top: 30px;
    padding: 15px;

    -moz-box-shadow: 3px 3px 5px 1px #ccc;
    -webkit-box-shadow: 3px 3px 5px 1px #ccc;
    box-shadow: 3px 3px 5px 1px #ccc;

    .premio {
      /* margin-top: -60px; */
      width: 63vw;
      height: 30vh;
    }
    

    #error {
      display: block;
      position: absolute;
      svg {
        position: absolute;
        top: 80%;
        right: 1vw;
      }

      span {
        display: none;
      }
    }

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;

    }

    label {
      font-size: 1rem;

      small {
        font-size: 1rem;
        color: #e57878;
      }
    }

    input, select {
      height: 44px;
      padding: 5px;
      width: 30vw;

      margin: 5px;

      &::placeholder {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    textarea {
      height: 35vh;
      padding: 5px;
      width: 30vw;

      margin: 5px;
      resize: vertical;

      ::placeholder {
        font-size: 1rem;
      }

      &::placeholder {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    button {
      width: 20vw;
      margin: 20px 0px 0 36%;
    }

    #separador {
      input {
        width: 14.7vw;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    #formacao {
      align-items: center;
      input {
        width: 6vw;

        ::placeholder {
          font-size: 0.9rem;
        }
      }

      display: grid;
      grid-template-columns: 6vw 7vw 7vw 7vw;
    }

    #premio {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      /* font-weight: bold; */
      /* border: 0; */
      /* border-radius: 4px; */
      /* font-size: 1.2rem; */

      height: 30vh;
      width: 32vw;

      section {
        display: flex;
        align-items: center;

        Input {
          width: 18px;
        }
      }
    }

    #check {
      height: 46px;
      width: 64vw;
      background: rgba(0, 170, 170, 0.9);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 1.2rem;

      margin-top: 12vh;

      display: flex;
      justify-content: center;
      align-items: center;
      

      option {
        font-size: 1rem;
        margin-top: -15px;
      }

      Input {
        width: 18px;
      }
    }
  }

  @media (max-width: 700px) {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        input, select {
          height: 44px;
          padding: 5px;
          width: 55vw;

          margin: 5px;
        }

        textarea {
          height: 35vh;
          padding: 5px;
          width: 55vw;

          margin: 5px;
          resize: vertical;
        }
      }

      button {
        width: 30vw;
        margin: 10px 0;
      }

      #formacao {
        
        input {
          width: 10vw;
        }
        grid-template-columns: 14vw 12vw 17vw 7vw;
      }

      #premio {
        width: 60vw;

        section {
          display: flex;
          align-items: center;

          Input {
            width: 18px;
          }
        }
      }
    }
  }
`;

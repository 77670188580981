import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeftCircle } from "react-icons/fi";

import { Container } from "./styles";

import logo from "../../assets/logo_cadcti.svg";

function Error() {
  return (
    <Container>
      <div>
        <img src={logo} alt="CADCTI" />
        {/* <div> */}
          <h1>404 !</h1>
          <h2>Ops! Página não econtrada.</h2>
        {/* </div> */}
        
          <Link to="/">
            <FiArrowLeftCircle size={20} /> {""}
            <p>Voltar</p>
          </Link>
        
      </div>
    </Container>
  );
}

export default Error;

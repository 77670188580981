import React from "react";
import { Link } from "react-router-dom";

import Button from "../../components/Button";

import logo from "../../assets/logo_cadcti.svg";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_White_SECTI.png";
import logoSecti from "../../assets/BRASÃO_SECTI_PNG-02.png";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_SECTI_1.png";


import { Container, Content, Background } from "./styles";

function SignIn() {
  return (
    <Container>
      <Content>
        <img src={logo} alt="CADCTI" />

        <h1>Acesse o Sistema</h1>

        <Link to="/login" type="button">
          <Button type="submit">Login</Button>
        </Link>
        {/* <Button id="disable" type="submit" >Faça seu cadastro</Button> */}
        <Link to="/cadastro" type="button">
          <Button type="submit" >Faça seu cadastro</Button>
        </Link>
        
          <div id="secti"> 
            <img src={logoSecti} alt="SECTI-AL"  />
          </div>
        
      </Content>

      <Background/>
    </Container>
  );
}

export default SignIn;

import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpUser from "../pages/SignUpUser";

import Item1 from "../pages/SignUpInfoEmpresa/Item1";
import Item2 from "../pages/SignUpInfoEmpresa/Item2";
import Item3 from "../pages/SignUpInfoEmpresa/Item3";
import Item4 from "../pages/SignUpInfoEmpresa/Item4";
import Item5 from "../pages/SignUpInfoEmpresa/Item5";

import Main from "../pages/Main";
import Confirm from "../pages/Confirm";

import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";

import ProfileEmpresa from "../pages/ProfileEmpresa";
import EditInfoEmpresa from "../pages/EditInfoEmpresa";

import ProfileEmpresaView from "../pages/ProfileEmpresaView";
import ListUsers from "../pages/ListUsers";

import Error from "../pages/error";
import ForgotPassword from "../pages/ForgotPasword";

import DocumentsUtils from "../pages/DocumentsUtils";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/login" component={SignIn} />

      <Route path="/sucess" component={Confirm} />

      <Route path="/cadastro" component={SignUp} />
      <Route path="/addusers" component={SignUpUser} />

      <Route path="/infoempresa/form1" component={Item1} isPrivate />
      <Route path="/infoempresa/form2" component={Item2} isPrivate />
      <Route path="/infoempresa/form3" component={Item3} isPrivate />
      <Route path="/infoempresa/form4" component={Item4} isPrivate />
      <Route path="/infoempresa/form5" component={Item5} isPrivate />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/perfil" component={Profile} isPrivate />

      <Route path="/empresa" component={ProfileEmpresa} isPrivate />
      <Route path="/editInfo" component={EditInfoEmpresa} isPrivate />

      <Route path="/office/:id" component={ProfileEmpresaView} isPrivate />
      <Route path="/users" component={ListUsers} isPrivate />

      {/* <Route path="/project/:id" component={Project} isPrivate /> */}

      <Route path="/documentos-uteis" component={DocumentsUtils} isPrivate />
      
      <Route path="/novasenha" component={ForgotPassword} />
      <Route path="*" component={Error} />
    </Switch>
  );
}

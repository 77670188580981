import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "../../../services/history";
import api from "../../../services/api";

import { updateProfileSuccess, updateProfileFailure, addInfoempresaSuccess, addInfoempresaFailure } from "./actions";
// updateInfoempresaSuccess, updateInfoempresaFailure

export function* updateProfile({ payload }) {
  try {
    const { name, telefone, email, endereco, ...rest } = payload.data;

    const profile = Object.assign(
      { name, telefone, email, endereco },
      rest.oldPassword ? rest : {}
    );

    const response = yield call(api.put, "usersrepresentantes", profile);

    toast.success("Perfil atualizado com sucesso!");

    yield put(updateProfileSuccess(response.data));
    
  } catch (err) {
    toast.error(err.response.data.error);
    yield put(updateProfileFailure());
  }
}

export function* addInfoEmpresa({ payload }) {
  try {
    const { data } = payload;
    
    const response = yield call(api.post, "infoempresa", data);

    toast.success("Cadastro realizado!");

    yield put(addInfoempresaSuccess(response.data));
    history.push("/empresa");
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    yield put(addInfoempresaFailure());
  }
}

export function* updateInfoEmpresa({ payload }) {
  try {
    const { name, email, avatar, ...rest } = payload.data;

    const profile = Object.assign(
      { name, email, avatar },
      rest.oldPassword ? rest : {}
    );

    const response = yield call(api.put, "users", profile);

    toast.success("Perfil atualizado com sucesso!");

    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao atualizar perfil, confira seus dados!");
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile),
  takeLatest("@user/ADD_INFOEMPRESA_REQUEST", addInfoEmpresa),
  takeLatest("@user/UPDATE_ENFOEMPRESA_REQUEST", updateInfoEmpresa)
]);


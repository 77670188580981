import React, { useEffect, useState } from 'react';
import { FaIdCardAlt } from "react-icons/fa";
import { 
  Collapse, 
  Popconfirm, 
  Button, 
  Descriptions, 
  Card, 
  Skeleton, 
  Empty 
} from 'antd';
import { 
  FolderOpenFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import moment from "moment";
import { FaPrint, FaTrash } from "react-icons/fa"
import { toast } from "react-toastify";

import { Container, Header, InfoEmpresa, Empresa, Users , Credenciamento} from './styles';

import api from "../../services/api";
import history from "../../services/history";

function ProfileEmpresaView({ match }) {

  const { id } = match.params;

  const { Meta } = Card;

  const [empresa, setEmpresa] = useState([]);
  const [infoEmpresa, setInfoEmpresa] = useState([]);
  const [users, setUsers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const { Panel } = Collapse;

  useEffect(() => {
    api.get(`office/?id=${id}`).then((response) => {
      setEmpresa(response.data.empresa);
      setInfoEmpresa(response.data.empresa.info_empresa ? response.data.empresa.info_empresa : false);
      setUsers(response.data.users);
      setLoading(false);
    });
  }, [id]);

  function DownloadPDF() { 
    window.print();
  }

  const showPopconfirm = () => {
    setVisible(true);
  };

  // const restorePasswordUser = async (id, telefone) => {
    
  //   const response = await api.patch(`restorepassword?id=${id}`, { telefone });

  //   toast.success(response.data.message)

  // };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(async () => {
      await api.delete(`/empresa/${id}`).then((response) => {
        setVisible(false);
        setConfirmLoading(false);

        history.push("/");
        toast.success(response.data.message);
        
      });
      
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };


  return (
    <Container>
      <Header>
        <button className="downloadPDF" onClick={DownloadPDF}>
          <FaPrint size={25} />
        </button>

        <Popconfirm
          title="Excluir Cadastro ?"
          visible={visible}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button danger type="primary" onClick={showPopconfirm}>
            <FaTrash />
          </Button>
        </Popconfirm>
      </Header>
      <div id="divToPrint">
        <Empresa>
          <Descriptions
            bordered
            size="small"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            title={
              <div className="title">
                <strong>
                  {empresa.razao_social}
                </strong>
                <p>
                  {empresa.fantasia ?  ` ${empresa.fantasia}` : ""}
                </p>
              </div>
                
            }
            layout="vertical"
            style={{
              margin: "10px"
            }}
          >
            <Descriptions.Item
              span={4}
              style={{
                textAlign: "center"
              }}
            >
              <FaIdCardAlt size={60} color={"rgba(0, 180, 255, 0.9)"} />
            </Descriptions.Item>
            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  CNPJ:
                </strong>
              }
            >
              <small>
                { empresa.cnpj && empresa.cnpj.replaceAll(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5")}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Telefone:
                </strong>
              }
            >
              <small>
                {empresa.telefone && empresa.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Natureza Jurídica:
                </strong>
              }
            >
              <small>
                {empresa.natureza_juridica && empresa.natureza_juridica}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              span={2}
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Email:
                </strong>
              }
            >
              <small>
                {empresa.email && empresa.email}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Porte:
                </strong>
              }
            >
              <small>
                {empresa.porte && empresa.porte}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              span={2}
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Atividade Principal:
                </strong>
              }
            >
              <small>
                <strong>
                    {empresa.cnae_principal && empresa.cnae_principal}: {""}
                </strong> 
                {empresa.atividade_secundaria && empresa.atividade_secundaria}
              </small>
            </Descriptions.Item>


            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Atividade Secundária:
                </strong>
              }
            >
              <small>
                <strong>
                    {empresa.cnae_secundario && empresa.cnae_secundario}: {""}
                </strong> 
                {empresa.atividade_principal && empresa.atividade_principal}
              </small>
            </Descriptions.Item>

            <Descriptions.Item 
              span={3}
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Site:
                </strong>
              }
            >
              <small>{empresa.site}</small>
            </Descriptions.Item>

          </Descriptions>
          
      </Empresa>

        <div>
          <Collapse defaultActiveKey={['1','2']} bordered={false} className="site-collapse-custom-collapse">
            <Panel header="Informações da Empresa" key="1" className="site-collapse-custom-panel">
              <InfoEmpresa>
                {infoEmpresa ? (
                  <div>
                    <div id="header">
                      <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                  </div>
                    <Descriptions 
                      bordered
                      size="small"
                      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Histórico:
                          </strong>
                        }
                      >
                        <small>
                          {infoEmpresa.historico}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Área de Negócio:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.area_negocio}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Missão:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.missao}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Visão:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.visao}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Objetivos Estratégicos:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.objetivos_estrategicos}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Produtos e/ou serviços e seus diferenciais:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.produtos_servicos}
                        </small>
                      </Descriptions.Item>
                    </Descriptions>

                    <Descriptions 
                        bordered
                        size="small"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        layout="vertical"
                      >
                        <Descriptions.Item 
                          label={
                            <strong
                              style={{
                                color: "rgba(0, 180, 255, 0.9)"
                              }}
                            >
                              Mercado:
                            </strong>
                          }
                        >
                          <small>
                            {infoEmpresa.mercado}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Planos/projetos de crescimento/desenvolvimento e expansão:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.planos_projetos}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Premiação/certificado de participação em processo de aceleração (entre outros itens que a empresa tenha interesse em apresentar):
                          </strong>
                        }>
                          <small>{infoEmpresa.premiacao_certificado ? "" : "Não"}</small>
                          <small>{infoEmpresa.descricao_premio ? infoEmpresa.descricao_premio : ""}</small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número atual de empregados registrados:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.empresagdos_registrados}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número atual de empregados em Pesquisa e/ou Desenvolvimento:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.empregrados_pesq_desenv}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número de empregados por nível de formação:
                          </strong>
                        }>
                          <small>
                            Ensino Médio: {infoEmpresa.empregados_ensino_medio ? infoEmpresa.empregados_ensino_medio : 0}
                            <br />
                            Técnico: {infoEmpresa.empregados_ensino_tecnico ? infoEmpresa.empregados_ensino_tecnico : 0}
                            <br />
                            Graduação: {infoEmpresa.empregados_ensino_graduacao ? infoEmpresa.empregados_ensino_graduacao : 0}
                            <br />
                            Especialização: {infoEmpresa.empregados_ensino_especializacao ? infoEmpresa.empregados_ensino_especializacao : 0}
                            <br />
                            Mestrado: {infoEmpresa.empregados_ensino_mestrado ? infoEmpresa.empregados_ensino_mestrado : 0}
                            <br />
                            Doutorado: {infoEmpresa.empregados_ensino_doutorado ? infoEmpresa.empregados_ensino_doutorado : 0}
                            <br />
                            Pós-Doutorado: {infoEmpresa.empregados_ensino_pos_doutorado ? infoEmpresa.empregados_ensino_pos_doutorado : 0}
                          </small>
                        </Descriptions.Item>


                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Expectativa de contratação para os próximos 05 (cinco) anos:
                          </strong>
                        }>
                          <small>{infoEmpresa.expectativa_contratacao}</small>
                        </Descriptions.Item>
                      </Descriptions>

                    
                    {infoEmpresa.acesso_polo && 
                      <>
                        {infoEmpresa.justificativa_proposta ?
                          <Descriptions 
                          bordered
                          size="small"
                          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                          layout="vertical"
                          >
                          <Descriptions.Item label={
                              <strong
                                style={{
                                  color: "rgba(0, 180, 255, 0.9)"
                                }}
                              >
                                Razões/motivações para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                              </strong>
                            }
                          >
                            <small>
                              {infoEmpresa.justificativa_proposta}
                            </small>
                          </Descriptions.Item>
                          <Descriptions.Item></Descriptions.Item>
    
                          <Descriptions.Item label={
                            <strong
                              style={{
                                color: "rgba(0, 180, 255, 0.9)"
                              }}
                            >
                              Especificação técnica da infraestrutura necessária para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                            </strong>
                          }>
                            <small>
                              {infoEmpresa.infraestrutura}
                            </small>
                          </Descriptions.Item>
    
                          <Descriptions.Item label={
                            <strong
                              style={{
                                color: "rgba(0, 180, 255, 0.9)"
                              }}
                            >
                              Período pretendido para a Permissão de Uso do espaço no Centro de Inovação do Polo Tecnológico:
                            </strong>
                          }>
                            <small>
                              {infoEmpresa.periodo_uso}
                            </small>
                          </Descriptions.Item>
    
                          <Descriptions.Item label={
                            <strong
                              style={{
                                color: "rgba(0, 180, 255, 0.9)"
                              }}
                            >
                              Espaço físico (m²) necessário para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                            </strong>
                          }>
                            <small>
                              {infoEmpresa.espcao_fisico}
                            </small>
                          </Descriptions.Item>
    
                          </Descriptions>

                        : "Sem informação"
                        }
                      </>
                    }
                  </div>
                  
                ) : (
                  <div>
                    <h3>Empresa sem dados cadastrados!</h3>
                  </div>
                )}
              </InfoEmpresa>
            </Panel>



            <Panel header="Sócios" key="2">
              <Users>
                {users.map((user) => {
                  return (
                    <div key={user.id}>
                      <p>Nome: <small id="name"> {user.name} </small> </p>
                      <p>CPF: <small> {user.cpf} </small> </p>
                      <p>Email: <small> {user.email} </small> </p>
                      <p>Telefone: <small> {user.telefone} </small> </p>
                      {user.representante && <strong>Representante</strong> }
                    </div>
                  )
                })}
              </Users>
            </Panel>
          </Collapse>
        </div>
      </div>
      <Credenciamento>
        <Collapse defaultActiveKey={['4']} bordered={false} style={{ width: "70vw" }} >
          <Panel header="Credenciamento" key="4" className="site-collapse-custom-panel">
            {infoEmpresa.credentialing ? (
              // <div>
                <Descriptions 
                  bordered
                  size="small"
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                  layout="vertical"
                >
                  {/* Habilitação Jurídica: */}

                  <Descriptions.Item 
                    label={
                      <strong
                        style={{
                          color: "rgba(0, 180, 255, 0.9)"
                        }}
                      >
                        Habilitação Jurídica:
                      </strong>
                    }
                      span={3}
                  >
                    {/* Contrato Social ou Ato Constitutivo */}
                      {infoEmpresa.credentialing.contratoSocial_atoConstitutivo ?
                        <Card
                          style={{
                            width: "57vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.contratoSocial_atoConstitutivo.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Contrato Social ou Ato Constitutivo
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.contratoSocial_atoConstitutivo.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card> :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>Sem o arquivo</p>
                              <small>
                                Contrato Social ou Ato Constitutivo
                              </small>
                            </>  
                          }
                        />
                      }

                      {/* Documento de Identificação dos Sócios da Empresa */}

                      {infoEmpresa.credentialing.identificacao_socios ?
                        <Card
                          style={{
                            width: "57vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.identificacao_socios.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Documento de Identificação dos Sócios da Empresa
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.identificacao_socios.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card> :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>Sem o arquivo</p>
                              <small>
                                Documento de Identificação dos Sócios da Empresa
                              </small>
                            </>  
                          }
                        />
                      }

                      {/* Declaração de Cumprimento da Cota de Aprendizado (DCCA) */}

                      {infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado ?
                        <Card
                          style={{
                            width: "57vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Declaração de Cumprimento da Cota de Aprendizado (DCCA)
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card> :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>Sem o arquivo</p>
                              <small>
                                Declaração de Cumprimento da Cota de Aprendizado (DCCA)
                              </small>
                            </>  
                          }
                        />
                      }
                  </Descriptions.Item>

                  {/* Qualificação Técnica: */}
                  <Descriptions.Item
                    label={
                      <strong
                        style={{
                          color: "rgba(0, 180, 255, 0.9)"
                        }}
                      >
                        Qualificação Técnica:
                      </strong>
                    }
                    span={3}
                  >

                    {/* Projeto de Atividades a Serem Executadas no CIPT */}
                    {infoEmpresa.credentialing.atividades_a_serem_executadas ?
                      <Card
                        style={{
                          width: "57vw",
                          marginTop: 16,
                          textAlign: "center" 
                        }}
                        actions={[
                          <a href={infoEmpresa.credentialing.atividades_a_serem_executadas.downloadURL} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                          </a>
                        ]}
                      >
                        <Skeleton loading={loading} avatar active>
                          <Meta
                            avatar={
                              <FilePdfFilled style={{ fontSize: "2rem" }} />
                            }
                            title={
                              <p
                                style={{
                                  fontSize: "1.1rem"
                                }}
                              >
                                Projeto de Atividades a Serem Executadas no CIPT
                              </p>
                            }
                            description={
                              `${infoEmpresa.credentialing.atividades_a_serem_executadas.name}.pdf`
                            }
                          />
                        </Skeleton>
                      </Card> :
                      <Empty 
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <>
                            <p>Sem o arquivo</p>
                            <small>
                              Projeto de Atividades a Serem Executadas no CIPT
                            </small>
                          </>  
                        }
                      />
                    }

                    {/* Declaração de Qualificação Técnica */}
                    {infoEmpresa.credentialing.declaracao_qualificacao_tecnica ?
                      <Card
                        style={{
                          width: "57vw",
                          marginTop: 16,
                          textAlign: "center" 
                        }}
                        actions={[
                          <a href={infoEmpresa.credentialing.declaracao_qualificacao_tecnica.downloadURL} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                          </a>
                        ]}
                      >
                        <Skeleton loading={loading} avatar active>
                          <Meta
                            avatar={
                              <FilePdfFilled style={{ fontSize: "2rem" }} />
                            }
                            title={
                              <p
                                style={{
                                  fontSize: "1.1rem"
                                }}
                              >
                                Declaração de Qualificação Técnica <br />
                                (Quadro de funcionário com detalhamento das atividades desenvolvidas e minibio de cada funcionário)
                              </p>
                            }
                            description={
                              `${infoEmpresa.credentialing.declaracao_qualificacao_tecnica.name}.pdf`
                            }
                          />
                        </Skeleton>
                      </Card> :
                      <Empty 
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <>
                            <p>Sem o arquivo</p>
                            <small>
                              Declaração de Qualificação Técnica (Quadro de funcionário com detalhamento das atividades desenvolvidas e minibio de cada funcionário)
                            </small>
                          </>  
                        }
                      />
                    }
                  </Descriptions.Item>

                  {/* Qualificação Econômica e Financeira */}

                  <Descriptions.Item 
                      label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Qualificação Econômica e Financeira:
                        </strong>
                      }
                      span={3}
                    >
                      {/* Balanço Patrimonial e demonstrações contábeis do último exercício social */}
                      {infoEmpresa.credentialing.balanco_patrimonial_demonstracoes ?
                        <Card
                          style={{
                            width: "57vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.balanco_patrimonial_demonstracoes.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Balanço Patrimonial e demonstrações contábeis do último exercício social, já exigíveis e apresentados na forma da lei, <br />
                                  que comprovem a boa situação financeira da empresa, vedada a sua substituição por balancetes ou balanços <br />
                                  provisórios, podendo ser atualizados por índices oficiais quando encerrado há mais de três meses da data de <br />
                                  apresentação da proposta
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.balanco_patrimonial_demonstracoes.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card> :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>Sem o arquivo</p>
                              <small>
                                Balanço Patrimonial e demonstrações contábeis do último exercício social, já exigíveis e apresentados na forma da lei, que comprovem a boa situação financeira da empresa, vedada a sua substituição por balancetes ou balanços provisórios, podendo ser atualizados por índices oficiais quando encerrado há mais de três meses da data de apresentação da proposta
                              </small>
                            </>  
                          }
                        />
                      }

                      {/* Certidão negativa de falência ou concordata */}
                      {infoEmpresa.credentialing.certidao_negativa ?
                        <Card
                          style={{
                            width: "57vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_negativa.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão negativa de falência ou concordata
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_negativa.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card> :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>Sem o arquivo</p>
                              <small>
                                Certidão negativa de falência ou concordata
                              </small>
                            </>  
                          }
                        />
                      }

                    </Descriptions.Item>

                    {/* Regularidade Fiscal: */}

                    <Descriptions.Item 
                        label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Regularidade Fiscal:
                          </strong>
                        }
                        span={3}
                      >
                        {/* Certidão Federal */}
                        {infoEmpresa.credentialing.certidao_federal ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.certidao_federal.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Certidão Federal
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.certidao_federal.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Federal
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Certidão Estadual */}
                        {infoEmpresa.credentialing.certidao_estadual ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.certidao_estadual.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Certidão Estadual
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.certidao_estadual.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Estadual
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Certidão Municipal */}
                        {infoEmpresa.credentialing.certidao_municipal ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.certidao_municipal.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Certidão Municipal
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.certidao_municipal.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Municipal
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Certidão Trabalhista */}
                        {infoEmpresa.credentialing.certidao_trabalhista ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.certidao_trabalhista.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Certidão Trabalhista
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.certidao_trabalhista.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Trabalhista
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Certidão FGTS */}
                        {infoEmpresa.credentialing.certidao_fgts ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.certidao_fgts.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Certidão FGTS
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.certidao_fgts.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão FGTS
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Prova de inscrição no Cadastro Nacional de Pessoa Jurídica */}
                        {infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Prova de inscrição no Cadastro Nacional de Pessoa Jurídica
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Prova de inscrição no Cadastro Nacional de Pessoa Jurídica
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Declaração de que Não Emprega Menor em cumprimento do disposto no inciso XXXIII do artigo 7º */}
                        {infoEmpresa.credentialing.declaracao_nao_emprega_menor ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.declaracao_nao_emprega_menor.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Declaração de que Não Emprega Menor em cumprimento do disposto no inciso XXXIII do artigo 7º
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.declaracao_nao_emprega_menor.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Declaração de que Não Emprega Menor em cumprimento do disposto no inciso XXXIII do artigo 7º
                                </small>
                              </>  
                            }
                          />
                        }

                        {/* Prova de inscrição no cadastro de contribuintes municipal */}
                        {infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal ?
                          <Card
                            style={{
                              width: "57vw",
                              marginTop: 16,
                              textAlign: "center" 
                            }}
                            actions={[
                              <a href={infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal.downloadURL} target="_blank" rel="noopener noreferrer">
                                <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                              </a>
                            ]}
                          >
                            <Skeleton loading={loading} avatar active>
                              <Meta
                                avatar={
                                  <FilePdfFilled style={{ fontSize: "2rem" }} />
                                }
                                title={
                                  <p
                                    style={{
                                      fontSize: "1.1rem"
                                    }}
                                  >
                                    Prova de inscrição no cadastro de contribuintes municipal, relativo ao domicílio ou sede do licitante, pertinente ao seu ramo de atividade
                                  </p>
                                }
                                description={
                                  `${infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal.name}.pdf`
                                }
                              />
                            </Skeleton>
                          </Card> :
                          <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                Prova de inscrição no cadastro de contribuintes municipal, relativo ao domicílio ou sede do licitante, pertinente ao seu ramo de atividade
                                </small>
                              </>  
                            }
                          />
                        }

                    </Descriptions.Item>

                </Descriptions>
              // </div> 
              )
              : 
              (              
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 80,
                  }}
                  description={
                    <span>
                      Empresa sem credenciamento!
                    </span>
                  }
                />
            )}
          </Panel>
        </Collapse>
      </Credenciamento>
    </Container>
  );
}

export default ProfileEmpresaView;

import styled from "styled-components";

import background from "../../assets/foto_polo_filter.jpg";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;

  background: url(${background}) no-repeat;
  background-size: cover;

  align-items: center;
  justify-content: center;

  div {
    background: rgba(255, 255, 255, 01);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    img {
      width: 12vw;
    }

    h1 {
      font-size: 2rem;
      margin: 10px;
    }

    h4 {
      color: rgba(0, 0, 0, 0.8)
    }

    small {
      font-weight: 500;
      padding: 5px;
      font-size: 1.1rem;
    }

    svg {
      margin-right: 7px;
    }
  }
`;

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import { Scope } from "@unform/core";
import { FiLogIn, FiPlus, FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import cep from "cep-promise";
import { cpf } from "cpf-cnpj-validator"

import Input from "../../components/Input";
import Button from "../../components/Button";
import InputM from "../../components/InputMask";

import history from "../../services/history";
import api from "../../services/api";

import logo from "../../assets/logo_cadcti.svg";

import { Container } from "./styles";

function SignUpUser({ location }) {
  const id_empresa = location.state;

  const [users, setUsers] = useState([1]);
  const [UseEndereco, setUseEndereco] = useState([1]);
  const [valid, setValid] = useState(null);

  const formRef = useRef(null);

  function addNewSocio() {
    setUsers([...users, users.length + 1]);
  }

  function checkCep(e) {
    const numCep = e.target.value.replace(/\D/g, "");

    if (numCep.length === 8) {
      cep(numCep)
        .then((response) => {
          const { street, neighborhood } = response;
          setUseEndereco([
            ...UseEndereco,
            {
              rua: street,
              bairro: neighborhood,
            },
          ]);
        })
        .catch((err) => {
          toast.error("CEP não encontrado");
        });
    }
  }

  async function checkCPF(e) {
    const numcpf = e.target.value.replace(/\D/g, "");
    if (numcpf.length === 11) {
      const validate = cpf.isValid(numcpf);
      !validate ? setValid(`error`) : setValid(`success`);
    }
  }

  async function handleCancel() {
    try {
      await api.delete(`empresa/${id_empresa}`);

      history.push("/");
    } catch (error) {
      toast.error(`${error.response.data.error}`);
    }
  }

  async function handleSubmit(dataUsers) {
    try {
      await api.post("empresauser", dataUsers);
      history.push("/sucess");
    } catch (error) {
      toast.error(`${error.response.data.error}`);
    }
  }

  return (
    <Container>
      <img src={logo} alt="CADCTI" />

      <h1>Cadastro de Usuários</h1>
      <div>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {users.map((use) => (
            <Scope path={`use${use}`} key={use}>
              <p>{use === 1 ? "Sócio Representante" : "Sócio"}</p>
              <Input
                name="name"
                placeholder="Seu nome"
                id="nome"
                type="string"
                required
              />

              <div>
                <InputM
                  name="telefone"
                  placeholder="Seu telefone"
                  mask="(99)99999-9999"
                  minlength="14"
                  required
                />
                <Input name="email" placeholder="Seu e-mail" type="email" required />
                <InputM
                  name="cpf"
                  placeholder="CPF do sócio"
                  mask="999.999.999-99"
                  className={valid}
                  onChange={checkCPF}
                  required
                />

                <Scope path="endereco">
                  <InputM
                    name="cep"
                    placeholder="Seu CEP"
                    mask="99.999-999"
                    minlength="10"
                    onChange={checkCep}
                    required
                  />
                  <Input
                    name="rua"
                    placeholder="Sua Rua"
                    defaultValue={UseEndereco[use] ? UseEndereco[use].rua : ""}
                    required
                  />
                  <Input name="numero" placeholder="Nº" type="number"  required/>
                  <Input
                    name="bairro"
                    placeholder="Seu bairro"
                    defaultValue={
                      UseEndereco[use] ? UseEndereco[use].bairro : ""
                    }
                    required
                  />
                  <Input name="complemento" placeholder="Complemento" />
                </Scope>

                {use === 1 ? (
                  <>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Sua senha"
                      minlength="8"
                      required
                    />
                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirme Sua senha"
                      minlength="8"
                      required
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              <Input
                name="empresa"
                value={id_empresa}
                disabled={true}
                id="empresa"
              />
            </Scope>
          ))}

          <Button type="submit">{"Cadastrar"}</Button>
        </Form>
      </div>
      <Button className="plus" onClick={addNewSocio}>
        <FiPlus />
        Adicionar Socio
      </Button>
      <Button id="cancel" onClick={handleCancel}>
        <FiLogOut />
        Cancelar
      </Button>
      <Link to="/login" type="button">
        <FiLogIn />
        Já tenho login
      </Link>
    </Container>
  );
}

export default SignUpUser;

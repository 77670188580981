import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiSend } from "react-icons/fi";
import { FaIdCardAlt } from "react-icons/fa";
import { 
  Modal,
  Button,
  Tabs,
  Descriptions,
  Card,
  Empty,
  Skeleton,
  message
} from "antd";

import { 
  FolderOpenFilled,
  FilePdfFilled,
  DeleteFilled
} from '@ant-design/icons';

import moment from "moment";

import Credentialing from "../../components/Credentialing";

import UploadContratoSocialAtoConstitutivo from "../Uploads/UploadContratoSocialAtoConstitutivo";
import UploadIdentificacaoSocios from "../Uploads/UploadIdentificacaoSocios";
import UploadDeclaracaoCumprimentoCotaAprendizado from "../Uploads/UploadDeclaracaoCumprimentoCotaAprendizado";
import UploadAtividadesParaExecucao from "../Uploads/UploadAtividadesParaExecucao";
import UploadDeclaracaoQualificacaoTecnica from "../Uploads/UploadDeclaracaoQualificacaoTecnica";
import UploadBalancoPatrimonialDemonstracoes from "../Uploads/UploadBalancoPatrimonialDemonstracoes";
import UploadCertidaoNegativa from "../Uploads/UploadCertidaoNegativa";
import UploadCertidaoFederal from "../Uploads/UploadCertidaoFederal";
import UploadCertidaoEstadual from "../Uploads/UploadCertidaoEstadual";
import UploadCertidaoMunicipal from "../Uploads/UploadCertidaoMunicipal";
import UploadCertidaoTrabalhista from "../Uploads/UploadCertidaoTrabalhista";
import UploadCertidaoFgts from "../Uploads/UploadCertidaoFgts";
import UploadProvaInscricaoCadNacionalPJ from "../Uploads/UploadProvaInscricaoCadNacionalPJ";
import UploadDeclaracaoNaoEmpregaMenor from "../Uploads/UploadDeclaracaoNaoEmpregaMenor";
import UploadProvaInscricaoCadContribuintesMunicipal from "../Uploads/UploadProvaInscricaoCadContribuintesMunicipal";

import { storage } from "../../services/firebase";
import api from "../../services/api";
import history from "../../services/history";

import { Container, InfoEmpresa } from "./styles";


import { useDispatch } from "react-redux";
import { signOut } from "../../store/modules/auth/actions";
import { toast } from "react-toastify";

function DashUserEmpresa() {
  const { TabPane } = Tabs;
  const { Meta } = Card;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoEmpresa, setInfoEmpresa] = useState(false);
  const [editFiles, setEditFiles] = useState(false);
  const [loading, setLoading] = useState(true);

  const profile = useSelector((state) => state.user.profile);

  function handleEdit() {
    history.push("editInfo", infoEmpresa)
  }

  const dispatch = useDispatch();

  useEffect(() => {
    function handleSignOut() {
      dispatch(signOut());
    }

    api.get("infoempresa").then((response) => {
      setInfoEmpresa(response.data);
      setLoading(false);
      if (response.data) {
        if (!response.data.credentialing && profile.representante) {
          return setIsModalVisible(true);
        }
      }      
    }).catch((e) => {
      if (e.response && e.response.status){
        if (e.response.status === 401){ // not authorized
          handleSignOut(); // redirect to login
        }
      }
      if (e.message){
        toast.error(e.message)
      }
    })
  }, [profile, dispatch]);

  const handleOk = async () => {
    setIsModalVisible(false);
  };

  function handleCancel() {
    setIsModalVisible(false);
  };

  function handleEditFiles() {
    setEditFiles(true);
  };

  const handleDelFile = async (data) => {
    
    Object.keys(data).forEach(async function (use) {  
      await api.patch("delcredentialing", use).then(async (response) => {
          try {
            const storageRef = await storage.ref();
            var desertRef = storageRef.child(`ArquivosEmpresas/${data[use].name}-${data[use].hash}`);
            desertRef.delete();
            message.success(response.data);
          } catch (error) {
            message.error(response.data)
          }
        });
    });
  };

  const handleSaving = async () => {
    setEditFiles(false);
    window.location.reload();
  }

  return (
    <Container>
      <Modal
        bodyStyle={{height: '300px', overflowY: 'scroll'}}
        width="300px"
        title="Credenciamento"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div>
            <div
              style={{ 
                background: "rgba(0, 190, 255, 0.9)",
                width: '160px',
                float: 'right',
                border: 0 
              }}
              onClick={handleOk} 
            >
              <Credentialing/>
            </div>
            <div className="clearfix"></div>
          </div>
        }
      >
        <div>
          <p style={{ fontSize: "1.4rem" }}>
            Este Edital tem como objeto o credenciamento de pessoas jurídicas de base tecnológica ou que desenvolvam atividades de pesquisa, desenvolvimento e inovação, que, uma vez habilitadas, serão chamadas para ocupar, de forma onerosa, salas no Centro de Inovação do Polo Tecnológico, situado na Rua Melo Povoas, nº 106, Jaraguá, CEP 57022-230, Maceió, Alagoas.
          </p>
          <p style={{ fontSize: "1.4rem" }}>
            Poderão participar do processo de credenciamento, as empresas de base tecnológica ou que desenvolvam atividades de pesquisa, desenvolvimento e inovação que atenderem plenamente a todas as exigências constantes neste Edital e seus anexos, inclusive quanto à documentação e os requisitos de habilitação. A participação neste credenciamento implicará a aceitação integral e irrestrita das condições estabelecidas <a target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/edital%2FEdital%20de%20Credenciamento%20-%20Centro%20de%20Inovac%CC%A7a%CC%83o%20do%20Polo%20Tecnolo%CC%81gico.pdf?alt=media&token=2d84c3ed-e19c-4ec3-86aa-3e9f317b5d34">neste Edital.</a>
          </p>
        </div>
      </Modal>
      
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        title={
          <div className="title">
            <strong>
              {profile.empresa.razao_social}
            </strong>
            <p>
              {profile.empresa.fantasia ?  ` ${profile.empresa.fantasia}` : ""}
            </p>
          </div>
            
        }
        layout="vertical"
        style={{
          margin: "10px"
        }}
      >
        <Descriptions.Item
          span={3}
          style={{
            textAlign: "center"
          }}
        >
          <FaIdCardAlt size={60} color={"rgba(0, 180, 255, 0.9)"} />
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CNPJ:
            </strong>
          }
        >
          <small>
            { profile.empresa.cnpj.replaceAll(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5")}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Telefone:
            </strong>
          }
        >
          <small>
            {profile.empresa.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Email:
            </strong>
          }
        >
          <small>
            {profile.empresa.email}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CNAE - Principal:
            </strong>
          }
        >
           <small>{profile.empresa.cnae_principal}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CNAE - Secundario:
            </strong>
          }
        >
          <small>{profile.empresa.cnae_secundario}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Site:
            </strong>
          }
        >
          <small>{profile.empresa.site}</small>
        </Descriptions.Item>

      </Descriptions>

      <InfoEmpresa>
        {profile.empresa.info_empresa ? (
          <Tabs 
            defaultActiveKey="1" 
            type="card"
            size="small"
            style={{
              padding: "10px"
            }}
          >
            {/* Card 1 */}
            <TabPane tab="Dados" key="1">
                {infoEmpresa && (
                  <div>
                    <div id="header">
                      <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                        {profile.representante ? 
                          <Button onClick={handleEdit}>
                            Editar
                          </Button>
                        : ""}
                    </div>
                    <Descriptions 
                      bordered
                      size="small"
                      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Histórico:
                          </strong>
                        }
                      >
                        <small>
                          {infoEmpresa.historico}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Área de Negócio:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.area_negocio}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Missão:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.missao}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Visão:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.visao}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Objetivos Estratégicos:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.objetivos_estrategicos}
                        </small>
                      </Descriptions.Item>

                      <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Produtos e/ou serviços e seus diferenciais:
                        </strong>
                      }>
                        <small>
                          {infoEmpresa.produtos_servicos}
                        </small>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
            </TabPane>

            {/* CARD 2 */}
            <TabPane tab="Mercado" key="2">
              {infoEmpresa && (
                    <div>
                      <div id="header">
                        <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                          {profile.representante ? 
                            <Button onClick={handleEdit}>
                              Editar
                            </Button>
                          : ""}
                      </div>
                      <Descriptions 
                        bordered
                        size="small"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        layout="vertical"
                      >
                        <Descriptions.Item label={
                            <strong
                              style={{
                                color: "rgba(0, 180, 255, 0.9)"
                              }}
                            >
                              Mercado:
                            </strong>
                          }
                        >
                          <small>
                            {infoEmpresa.mercado}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Planos/projetos de crescimento/desenvolvimento e expansão:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.planos_projetos}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número atual de empregados registrados:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.empresagdos_registrados}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número atual de empregados em Pesquisa e/ou Desenvolvimento:
                          </strong>
                        }>
                          <small>
                            {infoEmpresa.empregrados_pesq_desenv}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Número de empregados por nível de formação:
                          </strong>
                        }>
                          <small>
                            Ensino Médio: {infoEmpresa.empregados_ensino_medio ? infoEmpresa.empregados_ensino_medio : 0}
                            <br />
                            Técnico: {infoEmpresa.empregados_ensino_tecnico ? infoEmpresa.empregados_ensino_tecnico : 0}
                            <br />
                            Graduação: {infoEmpresa.empregados_ensino_graduacao ? infoEmpresa.empregados_ensino_graduacao : 0}
                            <br />
                            Especialização: {infoEmpresa.empregados_ensino_especializacao ? infoEmpresa.empregados_ensino_especializacao : 0}
                            <br />
                            Mestrado: {infoEmpresa.empregados_ensino_mestrado ? infoEmpresa.empregados_ensino_mestrado : 0}
                            <br />
                            Doutorado: {infoEmpresa.empregados_ensino_doutorado ? infoEmpresa.empregados_ensino_doutorado : 0}
                            <br />
                            Pós-Doutorado: {infoEmpresa.empregados_ensino_pos_doutorado ? infoEmpresa.empregados_ensino_pos_doutorado : 0}
                          </small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Premiação/certificado de participação em processo de aceleração (entre outros itens que a empresa tenha interesse em apresentar):
                          </strong>
                        }>
                          <small>{infoEmpresa.premiacao_certificado ? "" : "Não"}</small>
                          <small>{infoEmpresa.descricao_premio ? infoEmpresa.descricao_premio : ""}</small>
                        </Descriptions.Item>

                        <Descriptions.Item label={
                          <strong
                            style={{
                              color: "rgba(0, 180, 255, 0.9)"
                            }}
                          >
                            Expectativa de contratação para os próximos 05 (cinco) anos:
                          </strong>
                        }>
                          <small>{infoEmpresa.expectativa_contratacao}</small>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  )}
            </TabPane>

            {/* CARD 3 */}
            <TabPane tab="Motivações" key="3">
              {infoEmpresa.acesso_polo && (
                <>
                {infoEmpresa.justificativa_proposta ? 
                  <div>
                  <div id="header">
                    <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                      {profile.representante ? 
                        <Button onClick={handleEdit}>
                          Editar
                        </Button>
                      : ""}
                  </div>
                  <Descriptions 
                    bordered
                    size="small"
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    layout="vertical"
                  >
                    <Descriptions.Item label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Razões/motivações para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                        </strong>
                      }
                    >
                      <small>
                        {infoEmpresa.justificativa_proposta}
                      </small>
                    </Descriptions.Item>

                    <Descriptions.Item label={
                      <strong
                        style={{
                          color: "rgba(0, 180, 255, 0.9)"
                        }}
                      >
                        Especificação técnica da infraestrutura necessária para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                      </strong>
                    }>
                      <small>
                        {infoEmpresa.infraestrutura}
                      </small>
                    </Descriptions.Item>

                    <Descriptions.Item label={
                      <strong
                        style={{
                          color: "rgba(0, 180, 255, 0.9)"
                        }}
                      >
                        Período pretendido para a Permissão de Uso do espaço no Centro de Inovação do Polo Tecnológico:
                      </strong>
                    }>
                      <small>
                        {infoEmpresa.periodo_uso}
                      </small>
                    </Descriptions.Item>

                    <Descriptions.Item label={
                      <strong
                        style={{
                          color: "rgba(0, 180, 255, 0.9)"
                        }}
                      >
                        Espaço físico (m²) necessário para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
                      </strong>
                    }>
                      <small>
                        {infoEmpresa.espcao_fisico}
                      </small>
                    </Descriptions.Item>

                  </Descriptions>
                </div>
                   : <p>Atualize suas informações para ocupação da empresa no Centro de Inovação do Polo Tecnológico.</p>
                }
                </>     
              )}
            </TabPane>

            {/* CARD 4: ARQUIVOS */}
            <TabPane tab="Credenciamento" key="4">
              {infoEmpresa.credentialing ? (
                <div>
                  <div id="header">
                    <small>Ultima atualização - {moment(infoEmpresa.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                    {profile.representante && !editFiles ? 
                      <Button onClick={handleEditFiles}>
                        Editar
                      </Button>
                    :
                      <Button 
                        onClick={handleSaving}
                        style={{
                          fontSize: "1rem"
                        }}
                      >
                        Salvar Alterações
                      </Button> 
                    }
                  </div>
                  <Descriptions 
                    bordered
                    size="small"
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    layout="vertical"
                  >
                    {/* Habilitação Jurídica: */}

                    <Descriptions.Item 
                      label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Habilitação Jurídica:
                        </strong>
                      }
                      span={3}
                    >
                      {/* Contrato Social ou Ato Constitutivo */}
                      {infoEmpresa.credentialing.contratoSocial_atoConstitutivo ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.contratoSocial_atoConstitutivo.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            // <EditOutlined key="setting" style={{ fontSize: "1.5rem" }} />,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({contratoSocial_atoConstitutivo: infoEmpresa.credentialing.contratoSocial_atoConstitutivo})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Contrato Social ou Ato Constitutivo
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.contratoSocial_atoConstitutivo.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        :
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Contrato Social ou Ato Constitutivo
                                </small>  
                              </>
                            :
                            <UploadContratoSocialAtoConstitutivo />
                          } 
                        />
                      }

                      {/* Documento de Identificação dos Sócios da Empresa */}

                      {infoEmpresa.credentialing.identificacao_socios ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.identificacao_socios.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({identificacao_socios: infoEmpresa.credentialing.identificacao_socios})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Documento de Identificação dos Sócios da Empresa
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.identificacao_socios.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Documento de Identificação dos Sócios da Empresa
                                </small>
                              </>
                            :
                            <UploadIdentificacaoSocios />
                          } 
                        />
                      }

                      {/* Declaração de Cumprimento da Cota de Aprendizado (DCCA) */}

                      {infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({declaracao_cumprimento_cota_aprendizado: infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="setting" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Declaração de Cumprimento da Cota de Aprendizado (DCCA)
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.declaracao_cumprimento_cota_aprendizado.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>Declaração de Cumprimento da Cota de Aprendizado (DCCA)</small>
                              </>
                            :
                            <UploadDeclaracaoCumprimentoCotaAprendizado />
                          } 
                        />
                      }
                    </Descriptions.Item>

                    {/* Qualificação Técnica: */}

                    <Descriptions.Item 
                      label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Qualificação Técnica:
                        </strong>
                      }
                      span={3}
                    >

                      {/* Projeto de Atividades a Serem Executadas no CIPT */}

                      {infoEmpresa.credentialing.atividades_a_serem_executadas ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.atividades_a_serem_executadas.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({atividades_a_serem_executadas: infoEmpresa.credentialing.atividades_a_serem_executadas})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Projeto de Atividades a Serem Executadas no CIPT
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.atividades_a_serem_executadas.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Projeto de Atividades a Serem Executadas no CIPT
                                </small>
                              </>
                            :
                            <UploadAtividadesParaExecucao />
                          } 
                        />
                      }

                      {/* Declaração de Qualificação Técnica */}

                      {infoEmpresa.credentialing.declaracao_qualificacao_tecnica ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.declaracao_qualificacao_tecnica.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({declaracao_qualificacao_tecnica: infoEmpresa.credentialing.declaracao_qualificacao_tecnica})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Declaração de Qualificação Técnica <br />
                                  (Quadro de funcionário com detalhamento das atividades desenvolvidas e minibio de cada funcionário)
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.declaracao_qualificacao_tecnica.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Declaração de Qualificação Técnica (Quadro de funcionário com detalhamento das atividades desenvolvidas e minibio de cada funcionário)
                                </small>
                              </>
                            :
                            <UploadDeclaracaoQualificacaoTecnica />
                          } 
                        />
                      }
                    </Descriptions.Item>

                    {/* Qualificação Econômica e Financeira */}

                    <Descriptions.Item 
                      label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Qualificação Econômica e Financeira:
                        </strong>
                      }
                      span={3}
                    >
                      {/* Balanço Patrimonial e demonstrações contábeis do último exercício social */}

                      {infoEmpresa.credentialing.balanco_patrimonial_demonstracoes ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.balanco_patrimonial_demonstracoes.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({balanco_patrimonial_demonstracoes: infoEmpresa.credentialing.balanco_patrimonial_demonstracoes})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Balanço Patrimonial e demonstrações contábeis do último exercício social, já exigíveis e apresentados na forma da lei, <br />
                                  que comprovem a boa situação financeira da empresa, vedada a sua substituição por balancetes ou balanços <br />
                                  provisórios, podendo ser atualizados por índices oficiais quando encerrado há mais de três meses da data de <br />
                                  apresentação da proposta
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.balanco_patrimonial_demonstracoes.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Balanço Patrimonial e demonstrações contábeis do último exercício social, já exigíveis e apresentados na forma da lei, que comprovem a boa situação financeira da empresa, vedada a sua substituição por balancetes ou balanços provisórios, podendo ser atualizados por índices oficiais quando encerrado há mais de três meses da data de apresentação da proposta
                                </small>
                              </>
                            :
                            <UploadBalancoPatrimonialDemonstracoes />
                          } 
                        />
                      }

                      {/* Certidão negativa de falência ou concordata */}

                      {infoEmpresa.credentialing.certidao_negativa ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_negativa.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_negativa: infoEmpresa.credentialing.certidao_negativa})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão negativa de falência ou concordata
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_negativa.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão negativa de falência ou concordata
                                </small>
                              </>
                            :
                            <UploadCertidaoNegativa />
                          } 
                        />
                      }
                    </Descriptions.Item>

                    {/* Regularidade Fiscal: */}

                    <Descriptions.Item 
                      label={
                        <strong
                          style={{
                            color: "rgba(0, 180, 255, 0.9)"
                          }}
                        >
                          Regularidade Fiscal:
                        </strong>
                      }
                      span={3}
                    >

                      {/* Certidão Federal */}
                      
                      {infoEmpresa.credentialing.certidao_federal ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_federal.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_federal: infoEmpresa.credentialing.certidao_federal})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão Federal
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_federal.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Federal
                                </small>
                              </>
                            :
                            <UploadCertidaoFederal />
                          } 
                        />
                      }

                      {/* Certidão Estadual */}

                      {infoEmpresa.credentialing.certidao_estadual ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_estadual.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_estadual: infoEmpresa.credentialing.certidao_estadual})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão Estadual
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_estadual.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Estadual
                                </small>
                              </>
                            :
                            <UploadCertidaoEstadual />
                          } 
                        />
                      }

                      {/* Certidão Municipal */}

                      {infoEmpresa.credentialing.certidao_municipal ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_municipal.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                             <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_municipal: infoEmpresa.credentialing.certidao_municipal})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão Municipal
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_municipal.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Municipal
                                </small>
                              </>
                            :
                            <UploadCertidaoMunicipal />
                          } 
                        />
                      }

                      {/* Certidão Trabalhista */}

                      {infoEmpresa.credentialing.certidao_trabalhista ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_trabalhista.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_trabalhista: infoEmpresa.credentialing.certidao_trabalhista})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão Trabalhista
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_trabalhista.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão Trabalhista
                                </small>
                              </>
                            :
                            <UploadCertidaoTrabalhista />
                          } 
                        />
                      }

                      {/* Certidão FGTS */}

                      {infoEmpresa.credentialing.certidao_fgts ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.certidao_fgts.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({certidao_fgts: infoEmpresa.credentialing.certidao_fgts})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Certidão FGTS
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.certidao_fgts.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Certidão FGTS
                                </small>
                              </>
                            :
                            <UploadCertidaoFgts />
                          } 
                        />
                      }

                      {/* Prova de inscrição no Cadastro Nacional de Pessoa Jurídica */}

                      {infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({prova_inscricao_cad_nacional_pj: infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Prova de inscrição no Cadastro Nacional de Pessoa Jurídica
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.prova_inscricao_cad_nacional_pj.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Prova de inscrição no Cadastro Nacional de Pessoa Jurídica
                                </small>
                              </>
                            :
                            <UploadProvaInscricaoCadNacionalPJ />
                          } 
                        />
                      }

                      {/* Declaração de que Não Emprega Menor em cumprimento do disposto no inciso XXXIII do artigo 7º */}

                      {infoEmpresa.credentialing.declaracao_nao_emprega_menor ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.declaracao_nao_emprega_menor.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({declaracao_nao_emprega_menor: infoEmpresa.credentialing.declaracao_nao_emprega_menor})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Declaração de que Não Emprega Menor em cumprimento do disposto no inciso XXXIII do artigo 7º
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.declaracao_nao_emprega_menor.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Prova de inscrição no Cadastro Nacional de Pessoa Jurídica
                                </small>
                              </>
                            :
                            <UploadDeclaracaoNaoEmpregaMenor />
                          } 
                        />
                      }

                      {/* Prova de inscrição no cadastro de contribuintes municipal */}

                      {infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal ?
                        <Card
                          style={{
                            width: "66vw",
                            marginTop: 16,
                            textAlign: "center" 
                          }}
                          actions={[
                            <a href={infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal.downloadURL} target="_blank" rel="noopener noreferrer">
                              <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                            </a>,
                            <Button
                              style={{
                                background: "none"
                              }}
                              onClick={() => handleDelFile({prova_inscricao_cad_contribuintes_municipal: infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal})}
                              disabled={!editFiles}
                            >
                              <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                            </Button>
                          ]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <FilePdfFilled style={{ fontSize: "2rem" }} />
                              }
                              title={
                                <p
                                  style={{
                                    fontSize: "1.1rem"
                                  }}
                                >
                                  Prova de inscrição no cadastro de contribuintes municipal, relativo ao domicílio ou sede do licitante, <br />pertinente ao seu ramo de atividade
                                </p>
                              }
                              description={
                                `${infoEmpresa.credentialing.prova_inscricao_cad_contribuintes_municipal.name}.pdf`
                              }
                            />
                          </Skeleton>
                        </Card>
                        : 
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            !editFiles ? 
                              <>
                                <p>Sem o arquivo</p>
                                <small>
                                  Prova de inscrição no cadastro de contribuintes municipal, relativo ao domicílio ou sede do licitante, <br />pertinente ao seu ramo de atividade
                                </small>
                              </>
                            :
                            <UploadProvaInscricaoCadContribuintesMunicipal />
                          } 
                        />
                      }
                    </Descriptions.Item>

                  </Descriptions>
                </div> )
                  : 
                  profile.representante ? (          
                      <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 80,
                          }}
                        >
                          <Button
                            onClick={() => setIsModalVisible(true)}
                            size="small"
                          >
                            Submeter Credenciamento
                          </Button>
                        </Empty>            
                  ) : (
                    
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 80,
                      }}
                      description={
                        <span>
                          Empresa ainda sem credenciamento!
                        </span>
                      }
                    />

                  )}
            </TabPane>
          </Tabs>

        ) : profile.representante ? (
          <div id="addInfo">
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 80,
                }}
                description={
                  <span>
                    Empresa ainda sem dados cadastrados!
                  </span>
                }
              >
                <Link to="infoempresa/form1">
                  <Button>
                    <FiSend color={"#fff"} />
                    Cadastrar dados da empresa
                  </Button>
                </Link>
              </Empty>            
          </div>
        ) : (
          
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 80,
            }}
            description={
              <span>
                Empresa ainda sem dados cadastrados!
              </span>
            }
          />

        )}
      </InfoEmpresa>
    </Container>
  );
}

export default DashUserEmpresa;

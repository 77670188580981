import styled from "styled-components";

export const Container = styled.div`
  /* width: 10vw; */
  display: flex;
  flex-direction: column;

  padding: 10px;

  #addInfo {
    display: flex;
    flex-direction: column;

    height: 80vh;

    justify-content: center;
    align-items: center;

    h3 {
      color: rgba(0, 0, 0, 0.7);
    }

    button {
      padding: 5px;
      height: 5vh;
      width: 20vw;
      margin-top: 10px;
      

      border: 0;
      border-radius: 4px;
      font-size: 1.2rem;
      transition: background 0.2s;

      svg {
        margin-right: 3px;
      }
    }
  }

  @media (max-width: 700px) {

    #addInfo {
      display: flex;
      align-items: center;

      padding: 0;
      margin: 0 0 0 12vw;

      h3 {
        font-size: 1.3rem;
      }
      button {
        width: 40vw;
      } 
    }
  }
`;


export const InfoEmpresa = styled.div`
  align-items: center;
  position: relative;
  /* left: 46%; */

  /* border: 1px solid #333; */

  /* -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc; */

  border-radius: 5px;
  margin: 0px 30px;

  height: 100%;
  padding: 5px;

  justify-content: center;

  div {

    #info1 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      -moz-box-shadow: 3px 3px 5px 1px #ccc;
      -webkit-box-shadow: 3px 3px 5px 1px #ccc;
      box-shadow: 3px 3px 5px 1px #ccc;
    }

    #info2 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      -moz-box-shadow: 3px 3px 5px 1px #ccc;
      -webkit-box-shadow: 3px 3px 5px 1px #ccc;
      box-shadow: 3px 3px 5px 1px #ccc;
    }

    #info3 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      -moz-box-shadow: 3px 3px 5px 1px #ccc;
      -webkit-box-shadow: 3px 3px 5px 1px #ccc;
      box-shadow: 3px 3px 5px 1px #ccc;
    }

    #info4 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      -moz-box-shadow: 3px 3px 5px 1px #ccc;
      -webkit-box-shadow: 3px 3px 5px 1px #ccc;
      box-shadow: 3px 3px 5px 1px #ccc;
    }

  }

  div {
    padding: 10px;
    margin-top: 30px;

    #header {
      /* background: #ccc; */
      width: 60vw;
      display: flex;
      position: absolute;
      top: -4vh;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px 0;

      button {
        width: 10vw;
        height: 5vh;
        font-size: 1.4rem;
        font-weight: 500;

        &:hover {
          background: rgba(0, 150, 255, 0.9);
        }
      }

      small {
        color: rgba(0, 0, 0, 0.4);
        font-size: 1rem;
      }

    }

   
  }

  p {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 550;
    margin: 0 5px;
    color: rgba(0, 180, 255, 0.9);
    padding: 5px;

    small {
      font-size: 1rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
      padding: 5px;

      /* background: #ccc; */
      border: 1px dashed rgba(0, 180, 255, 0.1);
      border-radius: 5px;
      margin-top: 2px;
    }

    #grid-2-columns {
      /* margin: -10px 0 0 -30px; */
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* justify-items: center; */
      /* align-items: center; */
    }

    #grid-3-columns {
      margin: -10px 0 0 -30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      /* align-items: center; */
    }

    #grid-4-columns {
      margin: -3px 0 0 -10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;

      small {
        display: flex;
        justify-content: center;
      }
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    align-items: center;
    position: absolute;

    border-radius: 5px;
    margin: 0px 0px;

    height: 100%;
    padding: 0px;

    justify-content: center;
    
    div {

      #info1 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

        small {
          width: 55vw;
        }

      }

      #info2 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

        small {
          width: 55vw;
        }

      }

      #info3 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

        #grid-4-columns {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          /* margin: -3px 0 0 -10px; */
          align-items: center;

          small {
            width: 15vw;
            margin: 0 5px;
          }
        }

      }

      #info4 {
        display: flex;
        flex-direction: column;
        width: 62vw;
        align-items: center;

      }

    }

    div {
      padding: 10px;
      margin-top: 30px;

      #header {
        display: flex;
        position: absolute;
        top: -4vh;
      }
    }

    p {
      font-size: 0.8rem;
      small {
        width: 24vw;
      }
    }
  }
`;

export const FileList = styled.div`
  margin-top: -20px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed rgba(0, 170, 170, 0.3);
    margin: 5px;
    

    div {
      margin-top: 0;
    }

  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: rgba(0, 170, 170, 0.8);
  }

  strong {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 5px;
  }
    
`;
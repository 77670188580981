import styled from "styled-components";
// import { darken, shade } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  width: 70vw;
  // height: 100vh;

  .title {
    color: rgba(0, 190, 255, 0.9);
    font-size: 1.5rem;
    margin-left: 5px;

    p {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 700px) {
    .title {
      color: rgba(0, 190, 255, 0.9);
      font-size: 1.4rem;

      p {
        font-size: 1.2rem;
      }
    }
  }
  
`;

export const InfoEmpresa = styled.div`
  /* align-items: center;
  position: relative;

  justify-content: center; */
  padding: 0 1vw;

  div {

     #header {
      display: flex;
      float: right;
      top: -10vh;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 5px 0; 

      button {
        width: 10vw;
        height: 4vh;
        font-size: 1.2rem;
        font-weight: 500;
        margin-left: 5px;

        &:hover {
          background: rgba(0, 150, 255, 0.9);
          color: #fff;
        }
      }

      /* small {
        color: rgba(0, 0, 0, 0.4);
        font-size: 1rem;
      } */

    }

   
  }

  /* p {
    color: rgba(0, 180, 255, 0.9);

    small {
      font-size: 1rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);

      border: 1px dashed rgba(0, 180, 255, 0.1);
      border-radius: 5px;
    }

    strong {
      color: rgba(0, 180, 255, 0.9);
    }
  } */

  /* a {
    text-decoration: none;
  }

  #addInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;

    p {
      display: flex;
      flex-direction: column;
      font-size: 1.1rem;
      margin: 0 2px;
    }

    a {
      text-decoration: none;

      button {
        padding: 5px;
        height: 5vh;
        width: 18vw;
        margin-top: 10px;

        border: 0;
        border-radius: 4px;
        font-size: 1.2rem;
        transition: background 0.2s;

        svg {
          margin-right: 3px;
        }
      }
    }
  } */

  @media (max-width: 700px) {

    div {
      #header {
        /* display: flex;
        float: right;
        top: -10vh;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 5px 0;  */

        button {
          width: 16vw;
        }
      }
    }
  }
`;

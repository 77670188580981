import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  width: 70vw;
  // height: 100vh;

  button {
    display: flex;
    align-items: center;
    /* justify-content: center; */

    svg {
      margin-right: 5px;
    }
  }
  
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    width: 70vw;
  }
`;

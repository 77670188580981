import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../services/api";

import Input from "../../components/Input";
import Button from "../../components/Button";

import { Container } from './styles';

function EditItem3({ listinfo }) {
  const formRef = useRef(null);

  async function handleUpdate(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        empresagdos_registrados: Yup.number().required(),
        empregrados_pesq_desenv: Yup.number().required(),
        empregados_ensino_medio: Yup.number().required(),
        empregados_ensino_tecnico: Yup.number().required(),
        empregados_ensino_graduacao: Yup.number().required(),
        empregados_ensino_especializacao: Yup.number().required(),
        empregados_ensino_mestrado: Yup.number().required(),
        empregados_ensino_doutorado: Yup.number().required(),
        empregados_ensino_pos_doutorado: Yup.number().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { 
        empresagdos_registrados, 
        empregrados_pesq_desenv, 
        empregados_ensino_medio, 
        empregados_ensino_tecnico, 
        empregados_ensino_graduacao, 
        empregados_ensino_especializacao, 
        empregados_ensino_mestrado, 
        empregados_ensino_doutorado,
        empregados_ensino_pos_doutorado,
        ...rest
       } = listinfo;

      await api.put("infoempresa", {...data, ...rest}, {
      }).then(() => {
        toast.success("Informações atualizadas!");
      }).catch(() => {
        toast.error("Erro na atualização das informações!");
      });


      
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Form ref={formRef} initialdata={listinfo} onSubmit={handleUpdate}>
        <div>
          <label>
            <small>*</small> Número atual de empregados registrados:
            <Input
              name="empresagdos_registrados"
              type="number"
              defaultValue={listinfo.empresagdos_registrados}
            />
          </label>

          <label>
            <small>*</small> Número atual de empregados em Pesquisa e/ou Desenvolvimento:
            <Input
              name="empregrados_pesq_desenv"
              type="number"
              defaultValue={listinfo.empregrados_pesq_desenv}
            />
          </label>
          
          <label>
            <small>*</small> Número de empregados por nível de formação:
            <div id="formacao">
              <strong>Ensino Médio:</strong>
              <Input
                name="empregados_ensino_medio"
                defaultValue={listinfo.empregados_ensino_medio}
                type="number"
              />
              <strong>Técnico:</strong>
              <Input name="empregados_ensino_tecnico" defaultValue={0} type="number"/>
              <strong>Graduação:</strong>
              <Input
                name="empregados_ensino_graduacao"
                defaultValue={listinfo.empregados_ensino_graduacao}
                type="number"
              />
              <strong>Especialização:</strong>
              <Input
                name="empregados_ensino_especializacao"
                defaultValue={listinfo.empregados_ensino_especializacao}
                type="number"
              />
              <strong>Mestrado:</strong>
              <Input
                name="empregados_ensino_mestrado"
                defaultValue={listinfo.empregados_ensino_mestrado}
                type="number"
              />
              <strong>Doutorado:</strong>
              <Input
                name="empregados_ensino_doutorado"
                defaultValue={listinfo.empregados_ensino_doutorado}
                type="number"
              />
              <strong>Pós-Doutorado:</strong>
              <Input
                name="empregados_ensino_pos_doutorado"
                defaultValue={listinfo.empregados_ensino_pos_doutorado}
                type="number"
              />
            </div>
          </label>
        </div>

        <Button>Salvar alterações</Button>
      </Form>
    </Container>
  );
}

export default EditItem3;
import styled from "styled-components";
// import { darken, shade } from "polished";

export const Container = styled.div`
  width: 70vw;
  padding: 10px;
`;

export const Perfil = styled.div`
  /* border: 1px solid #3e3e3e; */
  border-radius: 5px;
  position: relative;
  /* left: 45%; */
  margin: 0 30px;
  padding: 10px;


  
  h1 {
    font-style: italic;
    padding: 5px;

    color: rgba(0, 170, 170, 0.8);
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    input {
      border: 2px solid rgba(0, 170, 170, 0.3);
      border-radius: 5px;
      height: 44px;
      padding: 5px;
      width: 41vw;
      color: rgba(0, 0, 0, 0.7);
      margin: 5px;

      &::placeholder {
        font-size: 1.3rem;
        color: rgba(0, 0, 0, 0.2);
      }

      &:focus {
        background: rgba(0, 170, 170, 0.3);
      }
    }

    button {
      padding: 5px;
      height: 6vh;
      width: 30vw;
      margin: 5px;

      margin-top: 15px;

      border: 0;
      border-radius: 4px;
      font-size: 1.2rem;
      transition: background 0.2s;
    }
  }
`;

import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../services/api";

import Input from "../../components/Input";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";

import { Container } from './styles';

function EditItem2({ listinfo }) {
  const formRef = useRef(null);

  async function handleUpdate(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        objetivos_estrategicos: Yup.string().required(),
        produtos_servicos: Yup.string().required(),
        mercado: Yup.string().required(),
        planos_projetos: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { objetivos_estrategicos, produtos_servicos, mercado, planos_projetos, ...rest } = listinfo;

      await api.put("infoempresa", {...data, ...rest}, {
      }).then(() => {
        toast.success("Informações atualizadas!");
      }).catch(() => {
        toast.error("Erro na atualização das informações!");
      });

      
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
  

  return (
    <Container>
      <Form ref={formRef} initialData={listinfo} onSubmit={handleUpdate}>
        <div>
          <label>
            <small>*</small> Objetivos Estratégicos:
            <TextArea
              name="objetivos_estrategicos"
              defaultValue={listinfo.objetivos_estrategicos}
            />
          </label>

          <label>
            <small>*</small> Produtos e/ou serviços e seus diferenciais:
            <TextArea
              name="produtos_servicos"
              defaultValue={listinfo.produtos_servicos}
            />
          </label>

          <label>
            <small>*</small> Mercado:
            <Input name="mercado" defaultValue={listinfo.mercado} />
          </label>

          <label>
            <small>*</small> Planos/projetos de crescimento/desenvolvimento e expansão:
            <TextArea
              name="planos_projetos"
              defaultValue={listinfo.planos_projetos}
            />
          </label>
        </div>

        <Button>Salvar alterações</Button>
      </Form>
    </Container>
  );
}

export default EditItem2;
import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  padding: 50px;
  
  .card {
    margin-top: 16vh;
  }

  svg {
    color: rgba(0, 50, 190, 0.9);
    font-size: 1.6rem;

    &:hover {
      color: rgba(0, 190, 255, 0.9);
    }
  }

  @media (max-width: 700px) {
    padding: 0 10px;
    .card {
      margin-top: 0;

      text {
        font-size: 1rem;
      }
    }
  }
`;

import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox } from "@material-ui/core";

import api from "../../services/api";

import Select from "../../components/Select";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";

import { Container } from './styles';

function EditItem5({ listinfo }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const formRef = useRef(null);

  async function handleUpdate(dataInfo) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        justificativa_proposta: Yup.string().required(),
        infraestrutura: Yup.string().required(),
        espcao_fisico: Yup.string().required(),
        periodo_uso: Yup.string().required(),
      });

      await schema.validate(dataInfo, {
        abortEarly: false,
      });

      handleClickOpen();
      setData({ ...dataInfo });

    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const Pop1 = () => {
    return <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                DECLARAÇÃO
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p>
                    Declaro, ao submeter a intenção de ocupação do Centro de Inovação do Polo Tecnológico do Jaraguá que a referida empresa cumpre de forma integral os pré-requisitos estabelecidos por esta consulta pública, a saber:
                  </p>
                  <br />
                  <p>
                    Esta consulta pública visa auferir a quantidade de eventuais empresas privadas que possuam o interesse em ocupar as salas do Centro de Inovação do Polo Tecnológico. Deste modo, o público-alvo desta consulta é voltado para quatro grupos: Empresarial, Institucional, Mecanismos e Conveniências.
                  </p>
                  <br />

                  <p>
                    Por "Público Empresarial", englobam: empresas intensivas em conhecimento, prioritariamente associadas às áreas tecnológicas e de inovação prioritárias para o polo TICS. Inserem neste grupo: empreendedores, startups, empresas âncoras ou de grande e médio porte.
                  </p>
                  <br />
                  <p>
                    Por "Público Institucional", englobam: Instituições de ciência, tecnologia e inovação (ICTs, instituições de apoio, entidade de classe e governo.
                  </p>
                  <br />

                  <p>
                    Por "Mecanismos", englobam: Empreendimentos que agregam valor ao Centro de Inovação do Polo Tecnológico, por oferecer atividades que incentivam o empreendedorismo, a inovação e o desenvolvimento de novos negócios. Dentre eles, estão pré-incubadoras, incubadoras, aceleradoras, laboratórios de P&D e o prototipagem/espaço maker, dentre áreas correlatas.
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions >
                <Checkbox name="check"
                  value="checkedA"
                  onChange={handleConfirmation}
                  />
                  <h4>Li e concordo comos termos acima</h4>
              </DialogActions>
            </Dialog>
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirmation() {
    const { 
      justificativa_proposta,
      infraestrutura,
      espcao_fisico,
      periodo_uso,
      ...rest
     } = listinfo;
  
    await api.put("infoempresa", {...data, ...rest}, {
    }).then(() => {
      toast.success("Informações atualizadas!");
      setOpen(false);
    }).catch(() => {
      toast.error("Erro na atualização das informações!");
    }); 
  }

  return (
    <Container>
      <Pop1/>
      <Form ref={formRef} initialData={listinfo} onSubmit={handleUpdate}>
        <div>

          <label>
            <small>*</small> Razões/motivações para a ocupação da empresa no Centro de Inovação do Polo Tecnológico:
            <TextArea
              name="justificativa_proposta"
              defaultValue={listinfo.justificativa_proposta}
            />
          </label>

          <label>
            <p><small>* </small>
               Especificação técnica da infraestrutura necessária para a ocupação
              da empresa no Centro de Inovação do Polo Tecnológico:
            </p>
            <TextArea
              name="infraestrutura"
              defaultValue={listinfo.infraestrutura}
            />
          </label>

          <label>
            <p><small>* </small>
              Qual o espaço físico (m²) necessário para a ocupação da empresa no
              Centro de Inovação do Polo Tecnológico?
            </p>
            <Select name="espcao_fisico" defaultValue={listinfo.espcao_fisico}>
              <option value="">Selecione o espaço físico (m²)</option>
              <option value="Salas Pequenas (20m2 - 28m2)">Salas Pequenas (20m2 - 28m2)</option>
              <option value="Salas Médias (46m2 - 72m2)">Salas Médias (46m2 - 72m2)</option>
              <option value="Salas Grandes (104m2 - 122m2)">Salas Grandes (104m2 - 122m2)</option>
            </Select>
            
          </label>

          <label>
            <small>*</small> Qual o período pretendido para a Permissão de Uso do espaço no Centro de Inovação do Polo Tecnológico?
            <Select name="periodo_uso" defaultValue={listinfo.periodo_uso}>
              <option value="">Selecione o período pretendido para uso do espaço</option>
              <option value="6 meses">6 meses</option>
              <option value="12 meses">12 meses</option>
              <option value="18 meses">18 meses</option>
              <option value="24 meses">24 meses</option>
              <option value="Mais de 24 meses">Mais de 24 meses</option>
              
            </Select>
            
          </label>
        </div>

        <Button>Salvar alterações</Button>
      </Form>
    </Container>
  );
}

export default EditItem5;

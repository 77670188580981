import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Form } from "@unform/web";
import { Scope } from "@unform/core";
// import { FiSend } from "react-icons/fi";
import * as Yup from "yup";

// import history from "../../services/history";
// import api from "../../services/api";

import { updateProfileRequest } from "../../store/modules/user/actions";

import Input from "../../components/Input";
import Button from "../../components/Button";

import { Container, Perfil } from "./styles";

function Profile() {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);

  const formRef = useRef(null);

  async function handleSubmit(dataUser) {
    try {

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string(),
        telefone: Yup.string(),
        email: Yup.string().email("Insira um e-mail válido."),
        oldPassword: Yup.string(),
        password: Yup.string().when("oldPassword", {
          is: (val) => val.leght,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
        confirmPassword: Yup.string()
          .when("oldPassword", {
            is: (val) => !!val.leght,
            then: Yup.string().required().min(8, "No minimo 8 caracteres"),
            otherwise: Yup.string(),
          })
          .oneOf([Yup.ref("password"), null], "Confirmação incorreta."),
      });

      await schema.validate(dataUser, {
        abortEarly: false,
      });

      dispatch(updateProfileRequest(dataUser));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Perfil>
        <h1>Seu Perfil</h1>
        <Form ref={formRef} initialData={profile} onSubmit={handleSubmit}>
          <Input name="name"  />
          { profile.empresa ? 
          <>
            <Input name="cpf" disabled={true} defaultValue={profile.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4")} />
            <Input name="telefone" defaultValue={profile.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")} />
            <Scope path="endereco">
              <Input name="cep" />
              <Input name="rua" />
              <Input name="numero" />
              <Input name="bairro" />
            </Scope>
          </>
           : "" }
          <Input name="email" />
          <Input
            name="oldPassword"
            defaultValue=""
            type="password"
            placeholder="Sua senha atual"
          />
          <Input
            name="password"
            defaultValue=""
            type="password"
            placeholder="Sua nova senha"
          />
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirme sua nova senha"
          />

          <Button type="submit">Salvar Alterações</Button>
        </Form>
      </Perfil>
    </Container>
  );
}

export default Profile;

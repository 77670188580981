import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;


  width: 68vw;
  // height: 100vh;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  #empty {
    display: flex;
    justify-content: center;
    width: 66vw;
  }

`;

export const Card = styled.div`
  border: 1px solid rgba(0, 170, 170, 0.1);
  border-radius: 5px;
  margin: 8px;
  padding: 10px;

  -moz-box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.1);
  -webkit-box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.1);
  box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.1);

  ul {
   
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      padding: 2px;
      border-bottom: 1px dashed rgba(0, 170, 170, 0.2);
    }

    li:last-child {
      border: 0;
    }
  }

  strong {
    font-size: 1rem;
  }

  small {
    font-size: 1.4rem;
    text-align: right;
  }

  &:hover{
    background: rgba(0, 120, 200, 0.1);
  }
`;

import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "../../../services/history";
import api from "../../../services/api";

import { signInSuccess, signFailure } from "./actions";

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, "sessions", {
      email,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push("/dashboard");
  } catch (err) {
    toast.error(err.response.data.error);
    yield put(signFailure());
  }
}

// export function* signUp({ payload }) {
//   try {
//     const { name, email, cargo, departament, admin } = payload;

//     yield call(api.post, "users", {
//       name,
//       email,
//       cargo,
//       departament,
//       admin
//     });

//     toast.success("Usuário cadastrado com sucesso.");
//   } catch (err) {
//     toast.error("Falha no cadastro, verifique os dados.");

//     yield put(signFailure());
//   }
// }

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),
  // takeLatest("@auth/SIGN_UP_REQUEST", signUp)
]);

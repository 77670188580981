import styled from "styled-components";

export const Container = styled.div`
  width: 70vw;
  padding: 10px;
`;

export const Stages = styled.div`
  display: flex;
  padding: 0 100px;

  justify-content: space-between;

  button {
    border: 1px solid rgba(0, 170, 170, 0.2);
    color: rgba(0, 170, 170, 0.9);
    background: transparent;
    /* background: rgba(0, 170, 170, 0.5); */
    /* border: 0; */
    border-radius: 100px;

    -moz-box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.2);
    -webkit-box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.2);
    box-shadow: 3px 3px 5px 1px rgba(0, 170, 170, 0.2);

    strong {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 30px;
    }

    &:hover {
      background: rgba(0, 170, 170, 0.5);
      color: #fff;
    }
  }

  #select {
    background: rgba(0, 170, 170, 0.5);
    color: #fff;
  }

  @media (max-width: 700px) {
    display: flex;
    padding: 0 100px;

    justify-content: center;

    button {
      margin: 0 3px;
      strong {
        padding: 25px;
      }
    }
  }

`;


import React from 'react';
import { Link } from "react-router-dom";
import { Empty } from 'antd';

import { Container, Content, Card } from './styles';

function ListEmpresasCard({ empresas }) {

  return (
    <Container>
      <Content>
        {empresas.map(empresa => {
          return (
            <Link to={`/office/?id=${empresa.id}`}>
              <Card>
                <ul>
                  <li>
                    <strong>Nome:</strong><small>{empresa.razao_social}</small>
                  </li>
                  <li>
                    <strong>Atividade Principal: </strong><small>{empresa.atividade_principal}</small>
                  </li>
                  <li>
                    <strong>Email: </strong><small>{empresa.email}</small>
                  </li>
                  <li>
                    <strong>Telefone: </strong><small>{empresa.telefone}</small>
                  </li>
                </ul>
              </Card>
            </Link>
          )
        })}
        { empresas.length === 0 ? 
          <div id="empty">
            <Empty description={false} />
          </div> 
          : ""
        }
      </Content>
    </Container>
  );
}

export default ListEmpresasCard;

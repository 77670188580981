import React, { useState } from 'react';
import {
  Modal,
  Button,
  Steps,
  Form,
  message,
  Upload,
  Checkbox
} from 'antd';

import crypto from "crypto";

import { UploadOutlined } from '@ant-design/icons';

import { storage } from "../../services/firebase";
import api from "../../services/api";

import { Container } from './styles';

function Credentialing() {
  const { Step } = Steps;

  const steps = [
    {
      title: 'Habilitação Jurídica',
    },
    {
      title: 'Qualificação Técnica',
    },
    {
      title: 'Qualificação Econômica e Financeira',
    },
    {
      title: 'Regularidade Fiscal',
    },
    {
      title: 'Declaração de que Não Emprega Menor'
    },
    {
      title: 'Acordo com as condições dispostas no EDITAL DE CREDENCIAMENTO Nº 01/2021/SECTI'
    }
  ];

  const [visible, setVisible] = useState();
  const [current, setCurrent] = useState(0);

  const [hashContratoSocialAtoConstitutivo, setHashContratoSocialAtoConstitutivo] = useState();
  const [hashIdentificacaoSocios, setHashIdentificacaoSocios] = useState();
  const [hashDeclaracaoCumprimentoCotaAprendizado, setHashDeclaracaoCumprimentoCotaAprendizado] = useState();
  const [hashAtividadesParaExecucao, setHashAtividadesParaExecucao] = useState();
  const [hashDeclaracaoQualificacaoTecnica, setHashDeclaracaoQualificacaoTecnica] = useState();
  const [hashBalancoPatrimonialDemonstracoes, setHashBalancoPatrimonialDemonstracoes] = useState();
  const [hashCertidaoNegativa, setHashCertidaoNegativa] = useState();
  const [hashCertidaoFederal, setHashCertidaoFederal] = useState();
  const [hashCertidaoEstadual, setHashCertidaoEstadual] = useState();
  const [hashCertidaoMunicipal, setHashCertidaoMunicipal] = useState();
  const [hashCertidaoTrabalhista, setHashCertidaoTrabalhista] = useState();
  const [hashCertidaoFgts, setHashCertidaoFgts] = useState();
  const [hashProvaInscricaoCadNacionalPJ, setHashProvaInscricaoCadNacionalPJ] = useState();
  const [hashDeclaracaoNaoEmpregaMenor, setHashDeclaracaoNaoEmpregaMenor] = useState();
  const [hashProvaInscricaoCadContribuintesMunicipal, setHashProvaInscricaoCadContribuintesMunicipal] = useState();
  const required = true;

  const handleCancel = () => setVisible(false);
  const handleOk = () => setVisible(true);


  const handleFile = (data) => {
    setCurrent(current + 1);
  }

  const handleChangeFile = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      return;
    }
  };

  const beforeUploadFile = (file) => {
    const isFile = file.type.indexOf('application/pdf') === 0;
    if (!isFile) {
      message.error('Importar somente arquivos PDF!');
    }
    
    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 10;
    if (!isLt5M) {
      message.error('Arquivo precisa ter menos de 10MB!');
    }
    return isFile && isLt5M;
  };

  // START UPLOAD 1 - ContratoSocialAtoConstitutivo
  const customUploadFileContratoSocialAtoConstitutivo = async ({ onError, onSuccess, file }) => {    
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashContratoSocialAtoConstitutivo(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);
        await api.patch("credentialing",
          { contratoSocial_atoConstitutivo: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileContratoSocialAtoConstitutivo = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashContratoSocialAtoConstitutivo}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { contratoSocial_atoConstitutivo: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 1 - ContratoSocialAtoConstitutivo

  // START UPLOAD 2 - IdentificacaoSocios
  const customUploadFileIdentificacaoSocios = async ({ onError, onSuccess, file }) => {    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashIdentificacaoSocios(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { identificacao_socios: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileIdentificacaoSocios = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashIdentificacaoSocios}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { identificacao_socios: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 2 - IdentificacaoSocios

  // START UPLOAD 3 - DeclaracaoCumprimentoCotaAprendizado
  const customUploadFileDeclaracaoCumprimentoCotaAprendizado = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashDeclaracaoCumprimentoCotaAprendizado(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { declaracao_cumprimento_cota_aprendizado: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileDeclaracaoCumprimentoCotaAprendizado = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashDeclaracaoCumprimentoCotaAprendizado}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { declaracao_cumprimento_cota_aprendizado: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 3 - DeclaracaoCumprimentoCotaAprendizado

  // START UPLOAD 4 - AtividadesParaExecucao
  const customUploadAtividadesParaExecucao = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashAtividadesParaExecucao(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { atividades_a_serem_executadas: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileAtividadesParaExecucao = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashAtividadesParaExecucao}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { atividades_a_serem_executadas: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 4 - AtividadesParaExecucao

  // START UPLOAD 5 - DeclaracaoQualificacaoTecnica
  const customUploadDeclaracaoQualificacaoTecnica = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashDeclaracaoQualificacaoTecnica(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { declaracao_qualificacao_tecnica: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileDeclaracaoQualificacaoTecnica = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashDeclaracaoQualificacaoTecnica}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { declaracao_qualificacao_tecnica: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 5 - DeclaracaoQualificacaoTecnica

  // START UPLOAD 6 - BalancoPatrimonialDemonstracoes
  const customUploadBalancoPatrimonialDemonstracoes = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashBalancoPatrimonialDemonstracoes(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { balanco_patrimonial_demonstracoes: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileBalancoPatrimonialDemonstracoes = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashBalancoPatrimonialDemonstracoes}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { balanco_patrimonial_demonstracoes: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 6 - BalancoPatrimonialDemonstracoes

  // START UPLOAD 7 - CertidaoNegativa
  const customUploadCertidaoNegativa = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoNegativa(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_negativa: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoNegativa = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoNegativa}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_negativa: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 7 - CertidaoNegativa

  // START UPLOAD 8 - CertidaoFederal
  const customUploadCertidaoFederal = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoFederal(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_federal: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoFederal = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoFederal}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_federal: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 8 - CertidaoFederal

  // START UPLOAD 9 - CertidaoEstadual
  const customUploadCertidaoEstadual = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoEstadual(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_estadual: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoEstadual = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoEstadual}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_estadual: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 9 - CertidaoEstadual

  // START UPLOAD 10 - CertidaoMunicipal
  const customUploadCertidaoMunicipal = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoMunicipal(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_municipal: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoMunicipal = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoMunicipal}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_municipal: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 10 - CertidaoMunicipal

  // START UPLOAD 11 - CertidaoTrabalhista
  const customUploadCertidaoTrabalhista = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoTrabalhista(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_trabalhista: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoTrabalhista = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoTrabalhista}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_trabalhista: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 11 - CertidaoTrabalhista

  // START UPLOAD 12 - CertidaoFgts
  const customUploadCertidaoFgts = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashCertidaoFgts(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { certidao_fgts: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileCertidaoFgts = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashCertidaoFgts}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { certidao_fgts: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 12 - CertidaoFgts

  // START UPLOAD 13 - ProvaInscricaoCadNacionalPJ
  const customUploadProvaInscricaoCadNacionalPJ = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashProvaInscricaoCadNacionalPJ(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { prova_inscricao_cad_nacional_pj: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileProvaInscricaoCadNacionalPJ = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashProvaInscricaoCadNacionalPJ}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { prova_inscricao_cad_nacional_pj: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 13 - ProvaInscricaoCadNacionalPJ

  // START UPLOAD 14 - DeclaracaoNaoEmpregaMenor
  const customUploadDeclaracaoNaoEmpregaMenor = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashDeclaracaoNaoEmpregaMenor(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { declaracao_nao_emprega_menor: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileDeclaracaoNaoEmpregaMenor = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashDeclaracaoNaoEmpregaMenor}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { declaracao_nao_emprega_menor: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 14 - DeclaracaoNaoEmpregaMenor

  // START UPLOAD 15 - ProvaInscricaoCadContribuintesMunicipal
  const customUploadProvaInscricaoCadContribuintesMunicipal = async ({ onError, onSuccess, file }) => {
    
    const storageRef = await storage.ref();
    const newHash = crypto.randomBytes(4).toString("hex");
    const name = file.name.split(".")[0];
    var DocFile = storageRef.child(`/ArquivosEmpresas/${name}-${newHash}`).put(file);
    setHashProvaInscricaoCadContribuintesMunicipal(newHash);

    DocFile.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // console.log(snapshot.state);

    }, function(error) {
      onError(error);
    }, function() {
      DocFile.snapshot.ref.getDownloadURL().then(async function(downloadURL) {
        onSuccess(null, downloadURL);

        await api.patch("credentialing",
          { prova_inscricao_cad_contribuintes_municipal: 
            {  name, downloadURL, hash: newHash }
          }).then((response) => {
            message.success(response.data)
        });
      });
    });
  };

  const handleDelFileProvaInscricaoCadContribuintesMunicipal = async (data) => {
    const storageRef = await storage.ref();
    const name = data.name.split(".")[0];
    var desertRef = storageRef.child(`ArquivosEmpresas/${name}-${hashProvaInscricaoCadContribuintesMunicipal}`);
    // desertRef.delete();

    desertRef.delete().then(async function() {
      await api.patch("delcredentialing", { prova_inscricao_cad_contribuintes_municipal: null });
    }).catch(function(error) {
      // console.log(error);
      // Uh-oh, an error occurred!
    });
  };
  // FINISHED UPLOAD 15 - ProvaInscricaoCadContribuintesMunicipal

  const handleFinished = async (data) => {
    setVisible(false);
    await api.patch("credentialing", data);
    window.location.reload();
  }

  return (
    <Container>
      <Modal
        className="modal"
        title="Credenciamento"
        visible={visible}
        onCancel={handleCancel}
        width={600}
        style={{
          marginTop: "-40px"
        }}
        footer={ 
          <>
            {/* <Button onClick={handleCancel} danger>Cancelar</Button> */}
            {/* <Button onClick={handleCancel} sucess>OK</Button> */}
          </>
        }
      > 
        <div>
          <Steps 
            current={current}
            size="small"
            // onChange={onChange}
            direction="vertical"
            style={{
              padding: "5px",
              marginBottom: "20px"
            }}
          >
            {steps.map(item => (
              <Step
                key={item.title}
                title={item.title}
              />
            ))}
          </Steps>
        </div>
        { current === 0 && 
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center"
              }}
            >

              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFile}
              >
                {/* START ContratoSocialAtoConstitutivo */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadContratoSocialAtoConstitutivo"
                  label="Contrato Social ou Ato Constitutivo"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="UploadContratoSocialAtoConstitutivo"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadFileContratoSocialAtoConstitutivo}
                    onRemove={handleDelFileContratoSocialAtoConstitutivo}
                    maxCount={1}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED ContratoSocialAtoConstitutivo */}

                {/* STRAT IdentificacaoSocios */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadIdentificacaoSocios"
                  label="Documentos de Identificação dos Sócios da Empresa"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="identificacao"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadFileIdentificacaoSocios}
                    onRemove={handleDelFileIdentificacaoSocios}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED IdentificacaoSocios */}

                {/* START DeclaracaoCumprimentoCotaAprendizado */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadDeclaracaoCumprimentoCotaAprendizado"
                  label="Declaração de Cumprimento da Cota de Aprendizado (DCCA)"
                  tooltip={{
                    title: "Declaração de Cumprimento Cota de Aprendizado (DCAA) de acordo com os termos estabelecidos no Art. 429 da CLT, a Lei 10.097/00, instituiu a contratação de jovem aprendiz visando dar a primeira oportunidade aos jovens no mercado de trabalho e definiu o percentual de contratação de acordo com o número de funcionários da empresa e das funções existentes. A contratação de aprendiz, é imprescindível para empresas que tenham acima de 7 empregados, exceto as empresas ME, EPP e optantes pelo Simples Nacional, ao qual a contratação torna-se facultativa e isenta dos benefícios fiscais. Para as empresas em geral a contratação corresponde de 5% a 15% do seu quadro de funcionários.",
                    color: "blue"
                  }}
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadFileDeclaracaoCumprimentoCotaAprendizado}
                    onRemove={handleDelFileDeclaracaoCumprimentoCotaAprendizado}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED DeclaracaoCumprimentoCotaAprendizado */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
              
            </div>
        }
        {
          current === 1 &&
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center"
              }}
            >
              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFile}
              >

                {/* START AtividadesParaExecucao */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadAtividadesParaExecucao"
                  label="Projeto de Atividades a Serem Executadas no CIPT"
                  tooltip={{
                    title:
                       <p>
                        O Projeto deve seguir o manual em anexo{" "}
                        <a style={{ color: "rgb(20, 20, 255)", fontWeight: "600" }} target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2Fmanual%20de%20plano%20de%20atividades.pdf?alt=media&token=99344993-ff98-4b99-ba02-8d790235f167">
                          AQUI
                        </a>
                      </p>,
                    color: "blue"
                  }}
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadAtividadesParaExecucao}
                    onRemove={handleDelFileAtividadesParaExecucao}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED AtividadesParaExecucao */}

                {/* START DeclaracaoQualificacaoTecnica */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadDeclaracaoQualificacaoTecnica"
                  labelAlign="left"
                  label={
                    <p
                      style={{ margin: "25px 0" }}
                    >
                      Declaração de Qualificação Técnica <br />
                      <small
                        style={{ margin: "20px 0" }}
                      >
                        (Quadro de funcionários com detalhamento das atividades desenvolvidas <br />e minibio de cada funcionário)
                      </small>
                    </p>
                  }
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadDeclaracaoQualificacaoTecnica}
                    onRemove={handleDelFileDeclaracaoQualificacaoTecnica}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED DeclaracaoQualificacaoTecnica */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }
        {
          current === 2 &&
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center"
              }}
            >
              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFile}
              >

                {/* START BalancoPatrimonialDemonstracoes */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadBalancoPatrimonialDemonstracoes"
                  labelAlign="left"
                  label={
                      <small
                        style={{ margin: "15px 0 15vh 0"}}
                      >
                        Balanço Patrimonial e demonstrações contábeis do último exercício social, já exigíveis e
                        <br/>apresentados na forma da lei, que comprovem a boa situação financeira da empresa,
                        <br/>vedada a sua substituição por balancetes ou balanços provisórios, podendo ser atualizados
                        <br/>por índices oficiais quando encerrado há mais de três meses da data de apresentação 
                        <br/>da proposta:
                      </small>
                  }
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadBalancoPatrimonialDemonstracoes}
                    onRemove={handleDelFileBalancoPatrimonialDemonstracoes}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED BalancoPatrimonialDemonstracoes */}

                {/* START CertidaoNegativa */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoNegativa"
                  label="Certidão negativa de falência ou concordata"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoNegativa}
                    onRemove={handleDelFileCertidaoNegativa}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED CertidaoNegativa */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }
        {
          current === 3 &&
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // textAlign: "center"
              }}
            >
               <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFile}
              >

                {/* START CertidaoFederal */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoFederal"
                  label="Certidão Federal"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoFederal}
                    onRemove={handleDelFileCertidaoFederal}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED CertidaoFederal */}

                {/* START CertidaoEstadual */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoEstadual"
                  label="Certidão Estadual"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoEstadual}
                    onRemove={handleDelFileCertidaoEstadual}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED CertidaoEstadual */}

                {/* START CertidaoMunicipal */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoMunicipal"
                  label="Certidão Municipal"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoMunicipal}
                    onRemove={handleDelFileCertidaoMunicipal}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* START CertidaoTrabalhista */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoTrabalhista"
                  label="Certidão Trabalhista"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoTrabalhista}
                    onRemove={handleDelFileCertidaoTrabalhista}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED CertidaoTrabalhista */}

                {/* START CertidaoFgts */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadCertidaoFgts"
                  label="Certidão FGTS"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadCertidaoFgts}
                    onRemove={handleDelFileCertidaoFgts}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED CertidaoFgts */}

                {/* START ProvaInscricaoCadNacionalPJ */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadProvaInscricaoCadNacionalPJ"
                  label="Prova de inscrição no Cadastro Nacional de Pessoa Jurídica"
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadProvaInscricaoCadNacionalPJ}
                    onRemove={handleDelFileProvaInscricaoCadNacionalPJ}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED ProvaInscricaoCadNacionalPJ */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }
        {
          current === 4 &&
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center"
              }}
            >
              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFile}
              >

                {/* START DeclaracaoNaoEmpregaMenor */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="UploadDeclaracaoNaoEmpregaMenor"
                  labelAlign="left"
                  label={
                    <p
                      style={{ margin: "25px 0" }}
                    >
                      Declaração de que Não Emprega Menor<br />
                      <small
                        style={{ margin: "20px 0" }}
                      >
                        em cumprimento do disposto no inciso XXXIII do artigo 7º
                      </small>
                    </p>
                  }
                  tooltip={{
                    title:
                       <p>
                        A Declaração deve ser feita em Timbrado, datada, assinada e carimbada pelo responsável pela empresa e o modelo pode ser encontrado{" "}
                        <a style={{ color: "rgb(20, 20, 255)", fontWeight: "600" }} target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/cadcti-53ec8.appspot.com/o/documentosUteis%2FModelo%20-%20Declarac%CC%A7a%CC%83o%20de%20que%20na%CC%83o%20emprega%20menor.pdf?alt=media&token=54aa67fa-6c32-456d-8890-89b34072e5d8">
                          AQUI
                        </a>
                      </p>,
                    color: "blue"
                  }}
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="contrato"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadDeclaracaoNaoEmpregaMenor}
                    onRemove={handleDelFileDeclaracaoNaoEmpregaMenor}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                        Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED DeclaracaoNaoEmpregaMenor */}

                {/* START ProvaInscricaoCadContribuintesMunicipal */}

                <Form.Item
                  style={{display : 'inline'}}
                  name="ProvaInscricaoCadContribuintesMunicipal"
                  labelAlign="left"
                  label={
                    <p
                      style={{ margin: "25px 0" }}
                    >
                      Prova de inscrição no cadastro de contribuintes municipal,<br />
                      <small
                        style={{ margin: "20px 0" }}
                      >
                        relativo ao domicílio ou sede do licitante, pertinente ao seu ramo de atividade
                      </small>
                    </p>
                  }
                  tooltip={{
                    title:
                       <p>
                          Pertinente ao seu ramo de atividade e compatível com o objeto contratual.
                      </p>,
                    color: "blue"
                  }}
                  rules={[
                    {
                      required: required,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Upload
                    name="file"
                    beforeUpload={beforeUploadFile}
                    onChange={handleChangeFile}
                    customRequest={customUploadProvaInscricaoCadContribuintesMunicipal}
                    onRemove={handleDelFileProvaInscricaoCadContribuintesMunicipal}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      style={{
                        margin: "10px",
                        width: "28vw",
                        color: "rgba(0, 190, 255, 0.9)",
                        borderColor: "rgba(0, 190, 255, 0.9)"
                      }}
                    >
                        Upload
                    </Button>
                  </Upload>
                </Form.Item>

                {/* FINISHED ProvaInscricaoCadContribuintesMunicipal */}

                {/*  */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }
        {
          current === 5 &&
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center"
              }}
            >
              <Form
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFinished}
              >
                <Form.Item
                  style={{display : 'inline', marginTop: "10px"}}
                  name="ciente_condicoes_edital"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject('Favor confirmar.'),
                    },
                  ]}
                >
                  <Checkbox>
                    A empresa, ao submeter esse credenciamento, está ciente e de acordo com as condições dispostas no EDITAL DE CREDENCIAMENTO Nº 01/2021/SECTI, bem como os valores de locação estipulados e demais exigências
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ 
                      background: "rgba(0, 190, 255, 0.9)", 
                      border: 0, 
                      color: "#fff",
                      float: "right",
                      marginBottom: "-40px"
                    }}
                  >
                    Ok
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }          
      </Modal>
      <Button 
        onClick={handleOk} 
        style={{ background: "none", border: 0, color: "#fff", marginTop: "-10px" }}
      >
        Enviar Arquivos
      </Button>
    </Container>
  );
}

export default Credentialing;

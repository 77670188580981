import React from "react";

import { Link } from "react-router-dom";

import "./styles.css"

import { useDispatch, useSelector } from "react-redux";

import logo from "../../assets/logo_cadcti.svg";
// import logoSecti from "../../assets/Marca-Governo-de-Alagoas_White_SECTI.png";
import logoSecti from "../../assets/BRASÃO_SECTI_PNG-02.png";

import { signOut } from "../../store/modules/auth/actions";

import { Menu, Typography, Col, Row } from 'antd';
import { UnorderedListOutlined, LogoutOutlined,
  HomeOutlined, UserOutlined, FolderOutlined } from '@ant-design/icons';

const { Title } = Typography;

function Sidebar() {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
  };
  
  return (
    <div className="sidebar">
      <Row justify="center">
        <Col span={10}>
          <img
            src={logo}
            alt="CADCTI" />
        </Col>
      </Row>
      <Row style={{marginTop: '10px'}} justify="center">
        <Col style={{textAlign: 'center'}} span={22}>
          <Title>{profile.name} </Title>
          <Title style={{textTransform: 'capitalize'}}>{profile.empresa ? profile.empresa.razao_social : "SECTI - AL"}</Title>
          <Title style={{textTransform: 'none'}}>{profile.email}</Title>
        </Col>
      </Row>
      <Row style={{marginTop: '20px'}} justify="center">
        <Menu style={{background: 'transparent'}} defaultSelectedKeys={'inicio'} mode="inline">
          <Menu.Item icon={<HomeOutlined />} key="inicio">
            <Link to="/">Início</Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key="perfil">
            <Link to="/perfil">Perfil</Link>
          </Menu.Item>
          { profile.admin && <Menu.Item icon={<UnorderedListOutlined />} key="usuarios">
          <Link to="/users">Usuários</Link></Menu.Item> }
          <Menu.Item icon={<FolderOutlined />} key="documentos">
            <Link to="/documentos-uteis">Docs</Link>
          </Menu.Item>
          <Menu.Item onClick={handleSignOut} icon={<LogoutOutlined />} key="logout">
            <strong>Sair</strong>
          </Menu.Item>
        </Menu>
      </Row>
      <Row style={{marginTop: '20px'}} justify="center">
        <Col xs={0} sm={22}>
          <img width="183" height="130" src={logoSecti} alt="SECTI-AL" />
          <Typography.Text style={{marginTop: '4px', fontSize: '10px'}}>© 2022 Todos os direitos reservados</Typography.Text>
        </Col>
      </Row>
    </div>
  );
}

export default Sidebar;

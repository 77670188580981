import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 18vw;
    max-width: 150px;
  }

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
  }

  form {
    display: grid;
    grid-template-columns: 0fr 0fr;
    padding: 10px;

    margin: 5px 0;
    width: 100vw;

    align-self: center;
    justify-content: center;

    input {
      border-radius: 5px;
      height: 44px;
      padding: 5px;

      width: 35vw;
      margin: 5px;
      font-size: 1.2rem;

      &::placeholder {
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    /* input + input {
      width: 100vw;
    } */

    span {
      color: #f64c75;
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
    }

    a {
      display: block;
      color: #333;
      margin-top: 15px;
      font-size: 1.3rem;
      opacity: 0.8;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }

    #grid {
      /* background: #ccc; */
      display: grid;
      grid-template-columns: 0fr 0fr;

      #num {
        width: 8vw;
      }

      #ativ {
        width: 26vw;
      }
    }

    button {
      width: 35vw;
      margin: 2px 0px 0 5px;
    }
  }

  > a {
    color: #5271ff;
    font-size: 1.6rem;
    opacity: 0.8;
    text-decoration: none;
    transition: background 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: ${shade(0.05, "#5271ff")};
    }
  }
`;

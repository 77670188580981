import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import history from "../../services/history";

import Button from "../../components/Button";
import TextArea from "../../components/TextArea";

import { Container } from "./styles";

function Item1() {
  const formRef = useRef(null);

  async function handleNext(item1) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        historico: Yup.string().required(),
        area_negocio: Yup.string().required(),
        missao: Yup.string().required(),
        visao: Yup.string().required(),
      });

      await schema.validate(item1, {
        abortEarly: false,
      });


      

      history.push("/infoempresa/form2", item1);
    } catch (err) {
      toast.error("Erro de validação, verifique os dados!");
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleNext}>
        <div>
          <label>
          <small>*</small> Histórico da empresa :
            <TextArea name="historico" placeholder="Histórico da empresa"  />
          </label>

          <label>
          <small>*</small> Área de Negócio:
            <TextArea name="area_negocio" placeholder="Área de Negócio" />
          </label>

          <label>
            <small>*</small> Missão:
            <TextArea name="missao" placeholder="Missão da empresa" />
          </label>

          <label>
            <small>*</small> Visão:
            <TextArea name="visao" placeholder="Visão da empresa" />
          </label>
        </div>

        <Button >Próximo</Button>
      </Form>
    </Container>
  );
}

export default Item1;

import styled from "styled-components";

export const Wrapper = styled.div`
  /* height: 100vh; */
  /* background: linear-gradient(-30deg, #5ce1e6, #5271ff); */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
`;

export const Content = styled.div`
  /* width: 100vw; */
  /* height: 100vh; */
  /* display: flex; */

  /* max-width: 315px; */
  /* text-align: center; */
  /* display: flex; */

  img {
    /* width: 50%; */
    /* border-radius: 20px; */
    /* box-shadow: 0 0 4px #333; */
  }
`;

import firebase from "firebase/app";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACHrJuYRBJeiJpMuS8UfX-D2aP1I7fbQg",
  authDomain: "cadcti-53ec8.firebaseapp.com",
  projectId: "cadcti-53ec8",
  storageBucket: "cadcti-53ec8.appspot.com",
  messagingSenderId: "1073243839077",
  appId: "1:1073243839077:web:f77d881a42a1d3157bc48c",
  measurementId: "G-03XDZHWDV3"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

import React, { useEffect, useState } from "react";
import { Input, Button, Popconfirm, Divider } from 'antd';
import { Collapse } from 'antd';
import { Descriptions } from 'antd';
import { toast } from "react-toastify";

import api from "../../services/api";

import { FaTrash } from "react-icons/fa"

import { Container, Header } from './styles';

function ListUsers() {

  const { Search } = Input;
  const { Panel } = Collapse;

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  function handleSearch(e) {
    setSearch(e.target.value);
    setLoading(true);
  }

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(async () => {
      try{
        await api.delete(`/usersrepresentantes/${userId}`).then((response) => {
          setVisible(false);
          setConfirmLoading(false);
          refreshUsers();
          toast.success(response.data.message);
          
        });
      }catch(e){
        if (e.response && e.response.data && e.response.data.error){
          toast.error(e.response.data.error);
        }else{
          if (e.message){
            toast.error(e.message);
          }
        }
        
        setVisible(false);
        setConfirmLoading(false);
      }

      
    }, 2000);
  };

  function refreshUsers(){
    api.get("usersrepresentantes").then((response) => {
      setUsers(response.data);
      setLoading(false);
    }).catch((e) => {
      if (e.response && e.response.data && e.response.data.error){
        toast.error(e.response.data.error);
      }
    });
  }
  
  useEffect(() => {
    api.get("usersrepresentantes").then((response) => {
      setUsers(response.data);
      setLoading(false);
    }).catch((e) => {
      if (e.response && e.response.data && e.response.data.error){
        toast.error(e.response.data.error);
      }
    });
  }, []);

  // Search
  useEffect(() => {
    api
      .get(`searchusers/?search=${search}`).then((response) => {
        setUsers(response.data);
        setLoading(false);
      }).catch((e) => {
        if (e.response && e.response.data && e.response.data.error){
          toast.error(e.response.data.error);
        }
      });
  }, [search]);

  return (
    <Container>
      <Header>
          <h3>Usuários Cadastrados</h3>
          <Search placeholder="Busca" loading={loading} onChange={handleSearch} enterButton />
      </Header>

      <Collapse>
        {users.map(user => {
          if (user.empresa !== null){
            return (
              <Panel header={user.representante ? `${user.name.toUpperCase()} : Representante` : user.name.toUpperCase()} extra={user.empresa.fantasia ? user.empresa.fantasia.toUpperCase() : user.empresa.razao_social.toUpperCase()}  key={user._id}>
                <Descriptions >
                  <Descriptions.Item label="CPF"> {user.cpf} </Descriptions.Item>
                  <Descriptions.Item label="Email"> {user.email} </Descriptions.Item>
                  <Descriptions.Item label="Telefone"> {user.telefone} </Descriptions.Item>
                  
                </Descriptions>
                <Descriptions title={`Empresa: ${user.empresa.razao_social.toUpperCase()}`}>
                  <Descriptions.Item label="CNPJ"> {user.empresa.cnpj} </Descriptions.Item>
                  <Descriptions.Item label="atividade_principal"> {user.empresa.atividade_principal} </Descriptions.Item>
                </Descriptions>
                <Divider dashed />
                <Descriptions>
                    <div>
                      <Popconfirm
                        title="Excluir Usuário ?"
                        visible={visible}
                        onConfirm={handleOk}
                        okButtonProps={{ loading: confirmLoading }}
                        onCancel={() => setVisible(false)}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Button danger type="primary" onClick={() => {setUserId(user._id); setVisible(true);}}>
                          <span style={{ marginRight: '4px' }}>Excluir Usuário</span> <FaTrash />
                        </Button>
                      </Popconfirm>
                    </div>
                  </Descriptions>
              </Panel>
            )
          }else{
            return (
              <Panel header={user.representante ? `${user.name.toUpperCase()} : Representante` : user.name.toUpperCase()} extra={<div style={{color: 'red'}}>USUARIO SEM EMPRESA CADASTRADA</div>}  key={user._id}>
                <Descriptions >
                  <Descriptions.Item label="CPF"> {user.cpf} </Descriptions.Item>
                  <Descriptions.Item label="Email"> {user.email} </Descriptions.Item>
                  <Descriptions.Item label="Telefone"> {user.telefone} </Descriptions.Item>
                </Descriptions>
                <Descriptions title={`ESSE USUÁRIO NÃO POSSUI EMPRESA CADASTRADA NO BANCO DE DADOS`}>
                  <div>
                    <Popconfirm
                      title="Excluir Usuário ?"
                      visible={visible}
                      onConfirm={handleOk}
                      okButtonProps={{ loading: confirmLoading }}
                      onCancel={() => setVisible(false)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Button danger type="primary" onClick={() => {setUserId(user._id); setVisible(true);}}>
                        <span style={{ marginRight: '4px' }}>Excluir Usuário</span> <FaTrash />
                      </Button>
                    </Popconfirm>
                  </div>
                </Descriptions>
              </Panel>
            )
          }
        })}
      </Collapse>
      
    </Container>
  );
}

export default ListUsers;
